import { IconNetwork } from 'common/IconNetwork'
import { useHideBodyScrollbar } from 'hooks/useHideBodyScrollbar'
import { useStore } from 'lib/store'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { NetworkSelectorDialog } from './components/NetworkSelectorDialog'

const _NetworkSelector: React.FC = () => {
  const store = useStore()
  const isOpen = store.ui.showNetworkSelector

  const networks = store.network.listSupportedNetworks()

  useHideBodyScrollbar(isOpen)

  const handleSelectNetwork = async (chainId: number) => {
    await store.wallets.switchNetwork(chainId)
    store.ui.closeNetworkSelector()
  }

  return (
    <>
      <IconNetwork
        onClick={() => {
          store.ui.openNetworkSelector()
        }}
      />

      <NetworkSelectorDialog
        isOpen={isOpen}
        onIgnore={() => store.ui.closeNetworkSelector()}
        onClick={(id) => {
          handleSelectNetwork(id)
        }}
        networks={networks}
      />
    </>
  )
}

export const NetworkSelector = observer(_NetworkSelector)
