import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

export const ModalPortal: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  useEffect(() => {
    let container = document.getElementById('modal-container')
    if (!container) {
      container = document.createElement('div')
      container.id = 'modal-container'

      const parent = document.getElementById('__next') || document.body.firstChild

      parent?.insertBefore(container, parent.firstChild)
    }

    // Set the styles to the modal container
    container.style.position = 'absolute'
    container.style.top = '0'
    container.style.right = '0'
    container.style.bottom = '0'
    container.style.left = '0'

    return () => {
      // Optional: Clean up the modal container on unmount if needed
      // document.body.removeChild(container!);
    }
  }, [])

  if (typeof window !== 'undefined') {
    const container = document.getElementById('modal-container')
    if (container) {
      return ReactDOM.createPortal(children, container)
    }
  }

  return null
}
