import { ComponentProps } from '@stitches/react'
import { Button } from 'common/Button'
import { Icon } from 'common/Icon'
import { Spinner } from 'common/Spinner'
import { theme, styled } from 'lib/theme'
import { Flex } from 'primitives/Flex'
import { Text } from 'primitives/Text'

export interface FeedEndProps extends ComponentProps<typeof Flex> {
  loading?: boolean
  error?: React.ReactNode
  onLoadMore?: () => void
  text?: string

  //empty states
  emptyState?: boolean
  subText?: string
  callToAction?: string
  clipboard?: boolean
  onClick?: () => void
}

const FeedEndText = styled(Text, {
  color: theme.colors.gray800,
  lineHeight: '16px',
})

export const FeedEnd: React.FC<FeedEndProps> = ({
  css,
  loading,
  error,
  onLoadMore,
  emptyState = false,
  clipboard = false,
  subText,
  callToAction,
  onClick,
  text = "You're all caught up",
}) => {
  const getTemplate = () => {
    if (error) {
      return typeof error === 'string' ? (
        <FeedEndText>{error}</FeedEndText>
      ) : (
        // ReactNode
        error
      )
    } else if (loading) {
      return (
        <Flex css={{ padding: '12px 24px' }}>
          <Spinner />
        </Flex>
      )
    } else if (emptyState) {
      return (
        <Flex
          column
          css={{
            alignItems: 'center',
            textAlign: 'center',
            padding: '12px 24px',
            gap: 10,
          }}
        >
          <Text type='body1' css={{ marginTop: '10px' }}>
            {text}
          </Text>
          <Text type='body2' subText>
            {subText}
          </Text>

          <Button
            theme='text'
            css={{ color: '$voilet100', textTransform: 'none' }}
            onClick={onClick}
          >
            <Flex css={{ gap: '5px' }}>
              {callToAction}
              {clipboard && (
                <Icon
                  icon='Clipboard'
                  css={{
                    width: '15px',
                    '& .primary.fill': {
                      fill: '$voilet100',
                    },
                  }}
                />
              )}
            </Flex>
          </Button>
        </Flex>
      )
    } else {
      return <FeedEndText>{text}</FeedEndText>
    }
  }
  return (
    <Flex
      center
      css={{
        width: '100%',
        padding: '12px 24px',
        ...css,
      }}
    >
      {getTemplate()}
    </Flex>
  )
}
