import { Button } from 'common/Button'
import { Icon } from 'common/Icon'
import { Modal } from 'common/Modal'
import { ModalCloseButton } from 'common/Modal/ModalCloseButton'
import { ModalContentDialog } from 'common/Modal/ModalContentDialog'
import { PopupDialog, PopupDialogProps } from 'common/PopupDialog'
import { PopupDialogContent } from 'common/PopupDialog/PopupDialogContent'
import { ConnectedWallet } from 'common/Web3/components/ConnectedWallet'
import { AppRoutes, DEFAULT_HOME_ROUTE } from 'config'
import { useStore } from 'lib/store'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { List } from 'primitives/List'
import { ListItem } from 'primitives/ListItem'
import { Text } from 'primitives/Text'
import { OnboardingModal } from 'components/modals/GettingStarted'

export type PostCardMoreMenuProps = PopupDialogProps

const _ProfileDrawer: React.FC<PostCardMoreMenuProps> = ({ isOpen, onIgnore, css }) => {
  const store = useStore()
  const router = useRouter()

  return (
    <>
      <PopupDialog isOpen={isOpen} onIgnore={onIgnore}>
        <PopupDialogContent css={css}>
          <List
            type="grouped"
            css={{
              [`& ${ListItem}`]: { padding: 0, margin: 0 },
              button: {
                gap: 15,
                textTransform: 'none',
                justifyContent: 'start'
              }
            }}
          >
            {store.network.walletNetwork && (
              <ListItem css={{ padding: 10 }}>
                <ConnectedWallet css={{ margin: '8px 5px' }} />
              </ListItem>
            )}
            <ListItem>
              <Button
                onClick={() => {
                  onIgnore()
                  router.push(`${AppRoutes.PROFILE}/${store.user.username}`)
                }}
              >
                <Icon
                  icon="Profile"
                  css={{
                    height: 22,
                    width: 22,
                    '& .primary.fill': {
                      fill: '$gray800'
                    }
                  }}
                />
                <Text type="body2" css={{ whiteSpace: 'nowrap' }}>
                  Profile
                </Text>
              </Button>
            </ListItem>
            <ListItem>
              <Button onClick={() => store.ui.openRefferalModal()}>
                <Icon
                  icon="Asterisk"
                  css={{
                    '& .primary.fill': {
                      fill: '$gray800'
                    },
                    height: 22,
                    width: 22
                  }}
                />
                <Text type="body2" css={{ whiteSpace: 'nowrap' }}>
                  Referrals
                </Text>
              </Button>
            </ListItem>
            {store.user.isCreator && (
              <ListItem>
                <Button onClick={() => store.ui.openCreatePost()}>
                  <Icon
                    icon="Add"
                    css={{
                      height: 22,
                      width: 22
                    }}
                  />
                  <Text type="body2" css={{ whiteSpace: 'nowrap' }}>
                    Create post
                  </Text>
                </Button>
              </ListItem>
            )}

            <ListItem>
              <Button
                onClick={() => {
                  store.ui.openGettingStartedModal()
                }}
              >
                <Icon
                  icon="Stack"
                  css={{
                    height: 22,
                    width: 22
                  }}
                />
                <Text type="body2" css={{ whiteSpace: 'nowrap' }}>
                  Onboarding
                </Text>
              </Button>
            </ListItem>
            <ListItem>
              <Button onClick={() => store.ui.openProfileSettingsModal()}>
                <Icon
                  icon="Settings"
                  css={{
                    height: 22,
                    width: 22
                  }}
                />
                <Text type="body2" css={{ whiteSpace: 'nowrap' }}>
                  Settings
                </Text>
              </Button>
            </ListItem>
            <ListItem>
              <Button
                css={{ marginLeft: '6px' }}
                onClick={() => {
                  setTimeout(() => {
                    store.user.logout()
                    onIgnore()
                    router.push(DEFAULT_HOME_ROUTE)
                  }, 350)
                }}
              >
                <Icon
                  icon="Logout"
                  css={{
                    height: 15,
                    width: 15
                  }}
                />
                <Text type="body2" css={{ whiteSpace: 'nowrap' }}>
                  Logout
                </Text>
              </Button>
            </ListItem>
          </List>
        </PopupDialogContent>

        <Modal
          isOpen={store.ui.getingStartedModal}
          onBackgroundClick={() => store.ui.closeGettingStartedModal()}
          animation="fadeIn"
          backdrop="blurred"
        >
          <ModalContentDialog css={{ height: '100%', '@lg': { width: '450px' } }}>
            <ModalCloseButton onClick={() => store.ui.closeGettingStartedModal()} />
            <OnboardingModal closeModal={() => store.ui.closeGettingStartedModal()} />
          </ModalContentDialog>
        </Modal>
      </PopupDialog>
    </>
  )
}

export const ProfileDrawer = observer(_ProfileDrawer)
