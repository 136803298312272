import { Button } from 'common/Button'
import { Icon } from 'common/Icon'

export const DrawerCloseButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <Button
      icon
      css={{
        '&:hover': { '.primary.stroke': { stroke: 'white' } },
      }}
      onClick={onClose}
    >
      <Icon icon='Cross' css={{ width: 35, heigh: 35 }} />
    </Button>
  )
}
