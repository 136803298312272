import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Avatar } from 'common/Avatar'
import { Icon } from 'common/Icon'
import { keyframes, styled, theme } from 'lib/theme'
import React from 'react'

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' }
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' }
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' }
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' }
})

const contentStyles = {
  minWidth: 180,
  zIndex: theme.zIndices.modal,
  backgroundColor: '$gray300',
  border: '1px solid $gray600',
  borderRadius: 6,
  padding: '3px 0',
  boxShadow: `0 0 15px 8px ${theme.colors.gray100}`,
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade }
  }
}

export const DropDownRoot = styled(DropdownMenu.Root, {})
export const DropDownTrigger = styled(DropdownMenu.Trigger, {})
export const DropdownPortal = styled(DropdownMenu.Portal)
export const DropdownMenuContent = styled(DropdownMenu.Content, contentStyles)
const DropdownMenuSubContent = styled(DropdownMenu.SubContent, contentStyles)

export const DropdownMenuArrow = styled(DropdownMenu.Arrow, {
  fill: '$gray600'
})

export const IconButton = styled('button', {
  all: 'unset',
  cursor: 'pointer'
})

const itemStyles = {
  all: 'unset',
  color: '$gray800',
  borderRadius: 3,
  gap: 5,
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  height: 35,
  padding: '2px 15px',
  position: 'relative',
  userSelect: 'none',
  cursor: 'pointer',
  '& .primary.stroke': {
    fill: '$gray800'
  },
  '& .primary.fill': {
    fill: '$gray800'
  },

  '&[data-disabled]': {
    color: '$gold100',
    pointerEvents: 'none'
  },

  '&[data-highlighted]': {
    transition: '0.4s',
    backgroundColor: '$gray400',
    p: { color: '$gray900 !important' },

    '& .primary.fill': {
      fill: '$gray900 !important'
    },
    '& .primary.stroke': {
      stroke: '$gray900 !important'
    }
  }
}

export const DropdownMenuItem = styled(DropdownMenu.Item, itemStyles)
const DropdownMenuCheckboxItem = styled(DropdownMenu.CheckboxItem, itemStyles)
const DropdownMenuRadioItem = styled(DropdownMenu.RadioItem, itemStyles)
const DropdownMenuSubTrigger = styled(DropdownMenu.SubTrigger, {
  '&[data-state="open"]': {
    backgroundColor: '$voilet100',
    color: '$voilet100'
  },
  ...itemStyles
})

const DropdownMenuLabel = styled(DropdownMenu.Label, {
  paddingLeft: 25,
  fontSize: 12,
  lineHeight: '25px',
  color: 'red'
})

export const DropdownMenuSeparator = styled(DropdownMenu.Separator, {
  height: 1,
  backgroundColor: '$gray600'
})

const DropdownMenuItemIndicator = styled(DropdownMenu.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const RightSlot = styled('div', {
  marginLeft: 'auto',
  paddingLeft: 20,
  color: 'red',
  '[data-highlighted] > &': { color: 'white' },
  '[data-disabled] &': { color: 'blue' }
})
