import { useLogin, usePrivy } from '@privy-io/react-auth'
import { Button } from 'common/Button'
import { useStore } from 'lib/store'
import { ComponentProps } from 'lib/theme'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

type Props = ComponentProps<typeof Button>

export const ConnectModal = observer(({ css, ...props }: Props) => {
  // ! Privy user wallet can change for the same user object in subsequent render cycles, it should not be used to match a linked wallet.
  const { user: privyUser, getAccessToken, linkWallet } = usePrivy()
  const store = useStore()
  const { authenticated, ready } = usePrivy()

  const isAuthenticated = ready && authenticated

  useEffect(() => {
    const authenticateUser = async () => {
      // Client needs to wait for privy user to be set, which then triggers WalletStateSync to set linked & connect wallets to determine connection state for transactions.
      store.wallets.setIsConnecting(true)
      const accessToken = await getAccessToken()

      if (accessToken && privyUser) {
        await store.user.authenticateWithPrivy(accessToken)
        store.user.setPrivyUser(privyUser)
      }
    }

    if (privyUser) authenticateUser()
  }, [privyUser])

  // https://docs.privy.io/guide/react/callbacks#uselogin
  const { login } = useLogin({
    onComplete: (user, isNewUser, wasAlreadyAuthenticated, loginMethod) => {
      console.log('login.onComplete()')
      console.log(user, isNewUser, wasAlreadyAuthenticated, loginMethod)
      // Any logic you'd like to execute if the user is/becomes authenticated while this
      // component is mounted
    },
    onError: (error) => {
      console.log('onError()')
      console.log(error)
      // Any logic you'd like to execute after a user exits the login flow or there is an error
    }
  })

  if (isAuthenticated) return null

  return (
    <Button
      onClick={() => {
        if (store.user.isLoggedIn) {
          return linkWallet()
        }
        login()
      }}
      theme="primary"
      css={{
        borderRadius: 8,
        minWidth: 'min-content',
        ...css
      }}
      {...props}
    >
      Connect
    </Button>
  )
})
