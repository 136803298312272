import { theme } from 'lib/theme'

import { useStore } from 'lib/store'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Flex } from 'primitives/Flex'
import { Text } from 'primitives/Text'

import { useRouter } from 'next/router'
import { Banner } from './Components/Banner'
import { BannerLayout } from './Components/BannerLayout'
import { Button } from 'primitives/Button'

// https://github.com/typescript-cheatsheets/react  look for: Why is React.FC not needed?
const _ApplicationBanner = () => {
  const { ui } = useStore()
  const router = useRouter()

  //https://mobx.js.org/observable-state.html#converting-observables-back-to-vanilla-javascript-collections
  const observerClone = ui.appBanner
    // Remove banners that should not display on current path
    .filter((banner) => !banner.opts.disabledPages?.includes(router.pathname))
    .sort((a, b) => Number(b.opts.pinned) - Number(a.opts.pinned))

  console.log('banners', observerClone.length)

  return (
    <>
      {ui.appBanner.length > 0 && (
        <BannerLayout>
          {observerClone.map(({ key, title, variant, opts: { onClick, actionLabel } }) => {
            return (
              <Flex
                key={key}
                css={{
                  minWidth: '100%',
                  justifyContent: 'center'
                }}
              >
                <Banner
                  default={variant === 'default'}
                  error={variant === 'error'}
                  success={variant === 'success'}
                >
                  <Flex
                    row
                    center
                    css={{
                      gap: '5px',
                      width: '100%',
                      justifyContent: 'space-between',
                      maxWidth: theme.container.maxWidth,
                      padding: '0 15px'
                    }}
                  >
                    <Text
                      type="body3"
                      css={{
                        color: '$gray900'
                      }}
                    >
                      {title}
                    </Text>

                    {onClick && (
                      <Flex>
                        <Button
                          css={{
                            color: 'white',
                            padding: '0 5px',
                            textDecoration: 'underline',
                            textTransform: 'none'
                          }}
                          onClick={onClick}
                        >
                          {actionLabel ?? 'Continue'}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                </Banner>
              </Flex>
            )
          })}
        </BannerLayout>
      )}
    </>
  )
}

export const ApplicationBanner = observer(_ApplicationBanner)
