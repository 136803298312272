import { useFundWallet, usePrivy, useWallets } from '@privy-io/react-auth'
import { AppRoutes } from 'config'
import { link } from 'fs'
import { RootStore } from 'models/RootStore'
import { NextRouter } from 'next/router'

export type ActionProps = {
  title: string
  subTitle?: string
}

const openInNewTabWithNoopener = (href: string) => {
  const aTag = document.createElement('a')
  aTag.rel = 'noopener'
  aTag.target = '_blank'
  aTag.href = href
  aTag.click()
}

export const creatorTitle = (title: string): ActionProps => {
  switch (title) {
    case 'hasLinkedWallet':
      return { title: 'Link a web3 wallet to your account' }
    case 'uploadedFirstPost':
      return {
        title: 'Publish your first post',
        subTitle: 'Say hello to the NSFW+ community'
      }
    case 'updatedBio':
      return {
        title: 'Update your profile bio',
        subTitle: 'Tell us more about yourself'
      }
    case 'uploadedAvatar':
      return {
        title: 'Set your profile picture',
        subTitle: 'TIP: Natural profile shots get more engagement'
      }
    case 'createdSubscriptionPlan':
      return {
        title: 'Configure subscriptions',
        subTitle: `Set the price and duration for as many plans as you'd like`
      }
    case 'firstWithdrawal':
      return {
        title: 'Withdraw earnings',
        subTitle: 'Make your first crypto withdrawal'
      }
    case 'setupMessaging':
      return {
        title: 'Configure messaging preferences',
        subTitle: 'Set a price and your inbox preferences'
      }
    default:
      return { title: 'No action ' }
  }
}
export const creatorActionClick = (
  title: string,
  store: RootStore,
  router: NextRouter,
  closeModal: () => void
) => {
  const { linkWallet } = usePrivy()

  switch (title) {
    case 'hasLinkedWallet':
      return () => {
        closeModal()

        console.log('TODO: Handle hasLinkedWallet click')
      }
    case 'uploadedFirstPost':
      return () => {
        closeModal()
        store.ui.openCreatePost()
      }

    case 'updatedBio':
      return () => {
        closeModal()
        store.ui.openProfileSettingsModal('CHANGE_BIO')
      }

    case 'uploadedAvatar':
      return () => {
        closeModal()
        if (store.user.account) {
          router.push(`${AppRoutes.PROFILE}/${store.user.account.username}`)
        }
      }

    case 'createdSubscriptionPlan':
      return () => {
        closeModal()
        store.ui.openProfileSettingsModal('CREATE_SUBSCRIPTION')
      }

    case 'firstWithdrawal':
      return () => {
        closeModal()
        router.push(AppRoutes.EARNINGS)
      }
    case 'setupMessaging':
      return () => {
        closeModal()
        store.ui.openProfileSettingsModal('MESSAGES')
      }

    default:
      return () => console.log()
  }
}

export const fanTitle = (title: string): ActionProps => {
  switch (title) {
    case 'hasLinkedWallet':
      return { title: 'Link a web3 wallet to your account' }

    case 'uploadedAvatar':
      return {
        title: 'Upload a profile avatar',
        subTitle: 'Click on avatar on your profile page'
      }

    case 'updatedBio':
      return {
        title: 'Update your profile bio',
        subTitle: 'Tell us more about yourself'
      }

    case 'firstLike':
      return {
        title: 'Like a post',
        subTitle: 'Let creators know what content you enjoy'
      }

    case 'firstFollow':
      return {
        title: 'Follow a creator',
        subTitle: 'Stay up to date in a personalized feed'
      }

    case 'firstTip':
      return {
        title: 'Tip a creator',
        subTitle: 'Show your appreciation with a tip!'
      }

    case 'firstExclusivePost':
      return {
        title: 'Purchase an exclusive post',
        subTitle: 'Enjoy unique content.'
      }

    case 'firstSubscription':
      return {
        title: 'Subscribe to a creator',
        subTitle: 'Unlocks subscription only content'
      }

    default:
      return { title: '' }
  }
}
export const fanActionClick = (
  title: string,
  store: RootStore,
  router: NextRouter,
  closeModal: () => void
) => {
  const { linkWallet, createWallet } = usePrivy()
  const { wallets } = useWallets()
  const { fundWallet } = useFundWallet()
  switch (title) {
    case 'hasLinkedWallet':
      return () => {
        closeModal()
        fundWallet(wallets[0].address, {
          config: {
            currencyCode: 'USDC_BASE',
            quoteCurrencyAmount: 20
          }
        })
      }
    case 'uploadedAvatar':
      return () => {
        closeModal()
        if (store.user.account) {
          router.push(`${AppRoutes.PROFILE}/${store.user.account.username}`)
        }
      }
    case 'updatedBio':
      return () => {
        closeModal()
        store.ui.openProfileSettingsModal()
      }
    case 'firstLike':
      return () => {
        closeModal()
        // openInNewTabWithNoopener('https://explodingtopics.com/')
      }
    case 'firstFollow':
      return () => {
        closeModal()
      }
    case 'firstTip':
      return () => {
        closeModal()
      }
    case 'firstExclusivePost':
      return () => {
        openInNewTabWithNoopener(
          'https://nsfwapp.notion.site/Purchasing-with-MetaMask-7d2734b4e41645c69e0b33176d2fe471'
        )
      }
    case 'firstSubscription':
      return () => {
        closeModal()
      }
    default:
      return () => console.log()
  }
}
