import { ComponentProps } from '@stitches/react'
import { useState, useMemo } from 'react'
import debounce from 'lodash/debounce'
import { theme } from 'lib/theme'
import { useSearchProfiles } from 'hooks/useSearchProfiles'
import { SearchBarDropdown } from 'common/SearchBarDropdown'
import { ProfileSearchResult } from 'common/ProfileSearchResult'
import { Text } from 'primitives/Text'
import { Box } from 'primitives/Box'
import { LoadMoreButton } from 'common/LoadMoreButton'

export interface ProfileSearchDropdownProps
  extends Partial<ComponentProps<typeof SearchBarDropdown>> {}

export const ProfileSearchDropdown: React.FC<ProfileSearchDropdownProps> = ({
  autoFocus,
  css,
}) => {
  const [searchText, setSearchText] = useState<string>()

  const { results, fetching, statusText, hasNext, loadMore } =
    useSearchProfiles(searchText)

  const debouncedSearch = useMemo(() => debounce(setSearchText, 250), [])

  return (
    <SearchBarDropdown
      onSearchChange={debouncedSearch}
      loading={fetching}
      css={css}
      autoFocus={autoFocus}
    >
      {({ close }) => (
        <Box
          css={{
            paddingTop: '12px',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            overflow: 'hidden',
            backgroundColor: theme.colors.gray400,
            ...css,
          }}
        >
          {statusText && (
            <Text
              type='body4'
              css={{
                color: theme.colors.gray800,
                padding: '24px 16px',
                borderTop: `1px solid ${theme.colors.gray500}`,
              }}
            >
              {statusText}
            </Text>
          )}
          {results?.map((profile) => (
            <ProfileSearchResult
              key={profile.profileId}
              profile={profile}
              handleClose={close}
            />
          ))}
          {hasNext && (
            <LoadMoreButton
              css={{ padding: '8px 0 16px 0' }}
              loading={fetching}
              onClick={(ev) => {
                ev.stopPropagation()
                loadMore()
              }}
            />
            // <Flex
            //   center
            //   css={{ minHeight: '48px', padding: '8px 0 16px 0' }}
            // >
            //   <Button
            //     icon
            //     theme='primary'
            //     loading={fetching}
            //     css={{ border: 'none' }}
            //     onClick={(ev) => {
            //       ev.stopPropagation()
            //       loadMore()
            //     }}
            //   >
            //     <Icon icon='Arrow' rotate={90} />
            //   </Button>
            // </Flex>
          )}
        </Box>
      )}
    </SearchBarDropdown>
  )
}
