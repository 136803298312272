import { useEffect } from 'react'

// The scrollbar can cause layout issues for absolute elements with a higher zindex i.e modals.
// Applying a margin does not interfere with the modals, and also then prevents layout glitching
// when removing the scrollbar width.
export const useHideBodyScrollbar = (isHidden?: boolean) => {
  useEffect(() => {
    const doc = window.document.documentElement
    doc.style.setProperty('--scrollbar-width', isHidden ? '0px' : '4px')
    doc.style.setProperty('--body-margin-right', isHidden ? '4px' : '0px')
  }, [isHidden])
}
