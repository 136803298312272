import { Icon } from 'common/Icon'
import { mapBadgeNamesByChainId, mapNetworkGradientsByChainId } from 'config/chainsMap'
import { useStore } from 'lib/store'
import { observer } from 'mobx-react-lite'
import { Button } from 'primitives/Button'
import { Chain } from 'viem'

interface Props {
  chain?: Chain
  onClick: () => void
}

export const IconNetwork: React.FC<Props> = observer(({ chain, onClick }) => {
  const store = useStore()
  const network = chain ?? store.wallets.network

  if (!network) return null
  const iconName = mapBadgeNamesByChainId[network.id]

  return (
    <Button
      onClick={onClick}
      css={{
        padding: '6px 12px',
        borderRadius: '50%',
        '&:hover': {
          filter: 'brightness(1.1)'
        }
      }}
    >
      <Icon
        icon={iconName}
        css={{
          svg: {
            width: '38px',
            height: '38px'
          }
        }}
      />
    </Button>
  )
})
