import { styled, theme } from 'lib/theme'
import { Flex } from 'primitives/Flex'

export const Banner = styled(Flex, {
  variants: {
    default: { true: { backgroundColor: theme.colors.voilet100 } },
    error: { true: { backgroundColor: theme.colors.red100 } },
    success: { true: { backgroundColor: '#D1D6E9' } }
  },
  justifyContent: 'center !important',
  padding: '0 25px',
  zIndex: '$layerOne',
  width: '100%',
  a: { cursor: 'pointer' },
  '@xs': {
    justifyContent: 'space-between',
    p: {
      fontSize: '12px'
    },
    a: { fontSize: '12px', whiteSpace: 'nowrap' }
  },
  '@md': {
    justifyContent: 'space-between',
    p: { fontSize: '13px' },
    a: { fontSize: '13px', margin: '0 0 0 4px' },
    '.close-link': {
      position: 'absolute',
      right: 25
    }
  }
})
