import { gql } from '@urql/core'
import { CORE_PROFILE_FIELDS } from 'graphql/fragments'
import { ProfilePaginatedResult } from 'interfaces'

export const SEARCH = gql`
  ${CORE_PROFILE_FIELDS}
  query Search($options: PaginationOptions, $query: String!) {
    search(options: $options, query: $query) {
      cursor
      hasNextPage
      items {
        ... on Profile {
          ...ProfileParts
        }
      }
    }
  }
`

export interface SearchResponse {
  search: ProfilePaginatedResult
}
