import { Avatar } from 'common/Avatar'
import { Button } from 'common/Button'
import { Icon } from 'common/Icon'
import { AppRoutes } from 'config'
import { Notification, NotificationType } from 'graphql/types'
import { isVideoAsset } from 'interfaces/guards'
import { toRelativeTime } from 'lib/date'
import { useStore } from 'lib/store'
import { styled } from 'lib/theme'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { Flex } from 'primitives/Flex'
import { Text } from 'primitives/Text'

const NotificationItem = styled(Flex, {
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  padding: 16,
  paddingLeft: 10,
  borderBottom: '1px solid $gray500',
  cursor: 'pointer',
  backgroundColor: '$gray300',
  '&:hover': {
    backgroundColor: '$gray400'
  },
  '&:active': {
    backgroundColor: '$gray100'
  }
})

const NotificationItemContent = styled(Flex, {
  gap: 8,
  alignItems: 'center'
})

const NotificationItemUnreadIndicator = styled(Flex, {
  width: 8,
  minWidth: 8,
  height: 8,
  borderRadius: '50%',
  variants: {
    read: {
      true: {
        backgroundColor: 'transparent'
      },
      false: {
        backgroundColor: '$voilet100'
      }
    }
  }
})

const _Notifications = () => {
  const { ui, user } = useStore()
  const router = useRouter()

  const markAsRead = (notificationId: string) => {
    return user.notifications.toggleNotificationRead(notificationId)
  }

  const profileRedirect = (profileId: string) => {
    ui.closeNotifications()
    router.push(`${AppRoutes.PROFILE}/${profileId}`)
  }

  const postRedirect = (postId: string) => {
    ui.closeNotifications()
    router.push(`${AppRoutes.POST}/${postId}`)
  }

  const isPostPublished = (type: NotificationType) => {
    return type === NotificationType.POST_PUBLISHED
  }
  const resolveAvatarSrc = (notification: Notification) => {
    switch (true) {
      case isPostPublished(notification.notificationType):
        return notification?.post?.asset?.[0]?.media.url
      default:
        return notification.actor?.avatar?.url
    }
  }

  const resolveSubTitleAction = (notification: Notification) => {
    switch (notification.notificationType) {
      case NotificationType.POST_COMMENT:
      case NotificationType.POST_LIKE:
      case NotificationType.POST_PUBLISHED:
        postRedirect(notification.postId!)
        break
      case NotificationType.PROFILE_FOLLOWED:
        profileRedirect(notification.actor!.username)
        break
      case NotificationType.WITHDRAWAL_CONFIRMED:
        router.push(AppRoutes.EARNINGS)
        break
      case NotificationType.POST_PURCHASED:
        ui.closeNotifications()
        router.push(AppRoutes.SALES)
        break
      case NotificationType.REFERRAL_ELIGIBLE:
        ui.closeNotifications()
        router.push(AppRoutes.REWARDS)
        break
      case NotificationType.REFERRAL_REWARD_ISSUED:
        ui.closeNotifications()
        router.push(AppRoutes.REWARDS)
        break
      default:
        markAsRead(notification.notificationId)
        break
    }
  }

  return (
    <>
      <Flex
        column
        css={{
          position: 'relative'
        }}
      >
        {user.notifications.items.map((notification) => (
          <NotificationItem
            key={notification.createdAt}
            onClick={(e) => {
              e.stopPropagation()
              if (!notification.isRead) {
                markAsRead(notification.notificationId)
              }
              resolveSubTitleAction(notification)
            }}
          >
            <NotificationItemContent>
              <NotificationItemUnreadIndicator read={notification.isRead} />
              {isPostPublished(notification.notificationType) &&
              isVideoAsset(notification?.post?.asset?.[0]?.media) ? (
                <Icon icon="Video" css={{ margin: '0px 3px' }} iconSize={32} />
              ) : (
                <Avatar
                  username={notification.actor?.username}
                  round={isPostPublished(notification.notificationType) ? false : true}
                  css={{
                    cursor: 'pointer',
                    alignSelf: 'center',
                    marginTop: '2px'
                  }}
                  onClick={async () => {
                    await markAsRead(notification.notificationId)
                    profileRedirect(notification.actor!.username)
                  }}
                  size="small"
                  src={resolveAvatarSrc(notification)}
                />
              )}

              <Flex column>
                <Flex
                  row
                  css={{
                    flexWrap: 'wrap',
                    gap: '0px 5px'
                  }}
                >
                  <Button
                    theme="text"
                    css={{ width: 'auto', padding: 0 }}
                    onClick={async () => {
                      await markAsRead(notification.notificationId)
                      profileRedirect(notification.actor!.username)
                    }}
                  >
                    <Text type="body3" css={{ color: '$gray900' }}>
                      {notification.actor?.displayName}
                    </Text>
                  </Button>
                  <Text
                    type="body3"
                    css={{
                      color: '$gray800'
                    }}
                  >
                    {notification.title}
                  </Text>
                </Flex>

                <Flex row css={{ gap: '5px' }}>
                  <Text type="body4" css={{ color: '$gray700' }}>
                    {toRelativeTime(notification.createdAt)}
                  </Text>
                  {notification.paymentAmount && (
                    <Text type="body4" css={{ color: '$gray700' }}>
                      &bull; {notification.paymentAmount} {notification.paymentCurrency}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </NotificationItemContent>
            <Button
              icon
              css={{
                all: 'unset',
                paddingRight: '3px',
                '&:hover': {
                  '& .primary.stroke': {
                    stroke: '$red100 !important'
                  }
                }
              }}
              onClick={async (e) => {
                e.stopPropagation()
                await user.notifications.deleteNotification(notification.notificationId)
                await user.notifications.fetchUnread()
              }}
            >
              <Icon icon="Trash" />
            </Button>
          </NotificationItem>
        ))}
      </Flex>
      {user.notifications.hasNextPage ? (
        <Flex css={{ padding: '20px' }}>
          <Button
            theme="text"
            css={{ color: '$voilet100' }}
            loading={user.notifications.loading}
            onClick={() => user.notifications.fetch()}
          >
            Load more
          </Button>
        </Flex>
      ) : (
        <Flex center css={{ padding: '30px' }}>
          <Text type="body2" subText>
            No more notifications
          </Text>
        </Flex>
      )}
    </>
  )
}

export const Notifications = observer(_Notifications)
