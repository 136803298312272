import { KnownIcon } from 'common/Icon'
import { SupportedEnv, SupportedEnvironments } from './environments'
import { Network, ProductionNetworks, TestingNetworks } from '@nsfw-app/crypto'

// ---- Badges -----
const betaBadgesByChainId: Record<ProductionNetworks | number, KnownIcon> = {
  [Network.BASE]: 'BadgeBase',
  [Network.OPTIMISM]: 'BadgeOptimism',
  [Network.POLYGON]: 'BadgePolygon',
  [Network.BINANCE]: 'BadgeBinance'
}
const stageBadgesByChainId: Record<TestingNetworks | number, KnownIcon> = {
  [Network.BASE_SEPOLIA]: 'BadgeBase', // FIXME
  [Network.OPTIMISM_SEPOLIA]: 'BadgeOptimism',
  [Network.POLYGON_AMOY]: 'BadgePolygon',
  [Network.BINANCE_TESTNET]: 'BadgeBinance'
}

const getTokens = (env: SupportedEnvironments) => {
  switch (env) {
    case SupportedEnv.BETA:
      return betaBadgesByChainId
    case SupportedEnv.STAGE:
      return stageBadgesByChainId
    default:
      throw new Error('Unsupported Environment')
  }
}

export const mapBadgeNamesByChainId = getTokens(
  process.env.NEXT_PUBLIC_NSFW_ENV as SupportedEnvironments
)

// ---- Gradients -----
const BNB_GRADIENT = 'linear-gradient(73.28deg, #403C3C 6.51%, #403721 88.45%)'
const MATIC_GRADIENT = 'linear-gradient(73.28deg, #2f1b52 6.51%, #7230FF 88.45%)'
const ETH_GRADIENT =
  'linear-gradient(150deg, rgba(2,0,36,0.1) 00%, rgba(9,9,121,0.1) 35%, rgba(0,212,255,0.1) 100%)'
const ARBITRUM_GRADIENT = 'linear-gradient(86.38deg, #28A0F0 3.92%, #0678C4 99.17%)'
const OPTIMISM_GRADIENT = 'linear-gradient(73.28deg, #f8642097 6.51%, #fd001e 88.45%)'

const BASE_GRADIENT = 'linear-gradient(86.38deg, #0000FF 3.92%, #0678C4 99.17%)'

const betaGradientsByChainId: Record<ProductionNetworks | number, string> = {
  [Network.BASE]: BASE_GRADIENT,
  [Network.OPTIMISM]: OPTIMISM_GRADIENT,
  [Network.POLYGON]: MATIC_GRADIENT,
  [Network.BINANCE]: BNB_GRADIENT
}
const stageGradientsByChainId: Record<TestingNetworks | number, string> = {
  [Network.BASE_SEPOLIA]: BASE_GRADIENT,
  [Network.OPTIMISM_SEPOLIA]: OPTIMISM_GRADIENT,
  [Network.POLYGON_AMOY]: MATIC_GRADIENT,
  [Network.BINANCE_TESTNET]: BNB_GRADIENT
}

const getGradients = (env: SupportedEnvironments) => {
  switch (env) {
    case SupportedEnv.BETA:
      return betaGradientsByChainId
    case SupportedEnv.STAGE:
      return stageGradientsByChainId
    default:
      throw new Error('Unsupported Environment')
  }
}

export const mapNetworkGradientsByChainId = getGradients(
  process.env.NEXT_PUBLIC_NSFW_ENV as SupportedEnvironments
)
