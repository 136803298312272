import { isServer } from 'lib/nextjs'
import { theme } from 'lib/theme'
import { useWindowSize } from 'react-use'

// We keep isMobile on store from agent sniffing, but sometimes you want to render/unrender a component
// just from the size, not specifically by device.
// This determines that from window size and configured theme.
export const useIsDesktopSize = (initial = false) => {
  const { width } = useWindowSize()
  return isServer()
    ? initial
    : !!(width && width > Number(theme.maxWidths.large.value))
}
