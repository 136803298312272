import { ComponentProps } from '@stitches/react'
import { theme } from 'lib/theme'
import { Box } from 'primitives/Box'
import { Children, useRef, useState } from 'react'
import { SearchField } from 'common/Inputs/SearchField'
import { useOutsideClick } from 'hooks/useOutsideClick'
import { useStore } from 'lib/store'
import { NSFW_EVENT } from 'lib/tracking/types'

export interface SearchBarDropdownProps extends ComponentProps<typeof Box> {
  onClose?: () => void
  loading?: boolean
  autoFocus?: boolean
  onSearchChange?: (val: string) => void
  children?:
    | React.ReactNode
    | ((props: { close?: () => void }) => React.ReactNode)
}

export const SearchBarDropdown: React.FC<SearchBarDropdownProps> = ({
  onSearchChange,
  onClose,
  css,
  children,
  loading,
  autoFocus,
}) => {
  const [value, setValue] = useState('')
  const searchBarRef = useRef<HTMLDivElement>(null)
  const store = useStore()

  const handleClose = () => {
    onClose?.()
    setValue('')
  }

  useOutsideClick(searchBarRef, () => handleClose())

  return (
    <Box
      css={{
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        ...css,
      }}
      ref={searchBarRef}
    >
      <Box css={{ position: 'relative' }}>
        <SearchField
          placeholder='Search'
          css={{
            position: 'relative',
            zIndex: '$layerTwo',
            marginBottom: '0px',
          }}
          value={value}
          onChange={(newValue) => {
            setValue(newValue)
            onSearchChange?.(newValue)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              handleClose()
            }
          }}
          loading={loading}
          autoFocus={autoFocus}
          onClick={() => {
            store.analytics.track(NSFW_EVENT.SEARCH_CLICK)
          }}
        />
        {value.length > 0 && (
          <Box
            css={{
              position: 'absolute',
              top: '34px',
              left: '0',
              right: '0',
              zIndex: '$layerOne',
              maxHeight: '50vh',
              overflow: 'auto',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
              boxShadow: theme.shadows.lg,
            }}
          >
            {typeof children === 'function'
              ? children({ close: handleClose })
              : children}
          </Box>
        )}
      </Box>
      {value.length > 0 && Children.count(children) > 0 && (
        <Box
          css={{
            display: 'none',
            backgroundColor: theme.colors.gray100,
            opacity: '50%',
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            '@lg': {
              display: 'block',
            },
          }}
          onClick={() => handleClose()}
        />
      )}
    </Box>
  )
}
