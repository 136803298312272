import { useState } from 'react'
import { ComponentProps } from '@stitches/react'
import { Flex } from 'primitives/Flex'
import { Heading } from 'primitives/Heading'
import { Text } from 'primitives/Text'
import { Icon, KnownIcon, LogoGradient } from 'common/Icon'
import { theme } from 'lib/theme'
import { ProgressScreens } from 'common/ProgressScreens'
import { Button } from 'common/Button'

// TODO: CMS driven?
const steps: { title: string; description: string; icon: KnownIcon }[] = [
  {
    title: 'Blockchain Meets Social',
    description: `Introducing the ultimate decentralized social platform for content creators, powered by blockchain technology.`,
    icon: 'Blockchain',
  },
  {
    title: 'Zero Fees For Creators',
    description: `To ensure content creators are fairly compensated for their efforts, we offer a platform with zero fees for creators, allowing them to retain 100% of their earnings.`,
    icon: 'Tags',
  },
  {
    title: 'Goverened by the NSFW+ DAO',
    description: `The governance of the product is made by its users, allowing for a more democratic and decentralized decision-making process.`,
    icon: 'Coins',
  },
]

interface StepWrapperProps extends ComponentProps<typeof Flex> {}

const StepWrapper: React.FC<StepWrapperProps> = ({ children }) => {
  return (
    <Flex column full center>
      <Flex
        column
        center
        css={{
          position: 'relative',
          textAlign: 'center',
          margin: '0 auto',
          borderRadius: '8px',
          height: '100%',
          '@sm': {
            height: '392px',
            padding: '64px',
            backgroundColor: theme.colors.gray400,
            maxWidth: '512px',
          },
          '@md': {
            height: '372px',
            maxWidth: '620px',
          },
        }}
      >
        {children}
      </Flex>
    </Flex>
  )
}

interface IntroModalContentProps extends ComponentProps<typeof Flex> {
  onContinue?: () => void
}

export const IntroModalContent: React.FC<IntroModalContentProps> = ({
  css,
  onContinue,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleContinue = () => {
    onContinue?.()
    setTimeout(() => setActiveIndex(0), 500)
  }

  return (
    <Flex column full css={css}>
      <Flex center>
        <LogoGradient css={{ width: 130, height: 50 }} />
      </Flex>
      <ProgressScreens
        showDots
        activeIndex={activeIndex}
        showDotsOnLastScreen={false}
      >
        {steps.map(({ title, description, icon }, i) => (
          <StepWrapper key={`intro_step_${i}`}>
            <Icon
              icon={icon}
              css={{
                width: '52px',
                height: '52px',
                margin: '0 auto 16px auto',
              }}
            />
            <Heading as='h6' css={{ marginBottom: '16px' }}>
              {title}
            </Heading>
            <Text
              subText
              type='body3'
              css={{
                margin: '0 auto 56px auto',
                maxWidth: '225px',
                '@sm': {
                  maxWidth: 'none',
                },
              }}
            >
              {description}
            </Text>
            {i === steps.length - 1 ? (
              <Button
                theme='primary'
                onClick={handleContinue}
                css={{
                  margin: '0 auto',
                  '@sm': {
                    position: 'relative',
                    bottom: 'auto',
                  },
                  transition: 'opacity 750ms ease-out',
                }}
              >
                Continue
              </Button>
            ) : (
              <Button
                theme='primary'
                round
                onClick={() => setActiveIndex(activeIndex + 1)}
                css={{ margin: '0 auto' }}
              >
                <Icon icon='Arrow' />
              </Button>
            )}
          </StepWrapper>
        ))}
      </ProgressScreens>
    </Flex>
  )
}
