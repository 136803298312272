import {
  Drawer as _Drawer,
  DrawerProps as _DrawerProps,
  Sx,
} from '@mantine/core'
import { theme } from 'lib/theme'
import { useHideBodyScrollbar } from 'hooks/useHideBodyScrollbar'

const drawerCss: Sx = {
  '.mantine-Drawer-drawer': {
    backgroundColor: theme.colors.gray100.value,
    boxShadow: '-4px -5px 13px #121217',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  '.mantine-Drawer-header': {
    display: 'none',
  },
}

interface DrawerProps extends _DrawerProps {
  // Control whether document body scrollbar is hidden when the modal opens (useful to disable when layering modals).
  lockScrollbar?: boolean
}

export const Drawer: React.FC<DrawerProps> = ({
  children,
  lockScrollbar = true,
  ...props
}) => {
  useHideBodyScrollbar(lockScrollbar && props.opened)

  return (
    <_Drawer
      sx={drawerCss}
      zIndex={Number(theme.zIndices.overlay.value)}
      // Important to set this to false for useHideBodyScrollbar behaviour
      lockScroll={false}
      padding='xs'
      position='right'
      {...props}
    >
      {children}
    </_Drawer>
  )
}
