import { usePrivy } from '@privy-io/react-auth'
import { Avatar } from 'common/Avatar'
import { Icon } from 'common/Icon'
import { ConnectedWallet } from 'common/Web3/components/ConnectedWallet'
import { AppRoutes, DEFAULT_HOME_ROUTE } from 'config'
import { WarningModal } from 'containers/WarningModal/WarningModal'
import { Account } from 'graphql/types'
import { useStore } from 'lib/store'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { Text } from 'primitives/Text'
import { useState } from 'react'
import {
  DropDownRoot,
  DropDownTrigger,
  DropdownMenuArrow,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownPortal,
  IconButton
} from './Components'

type ProfileDropDownProps = {
  user: Account
}
const _ProfileDropDown: React.FC<ProfileDropDownProps> = ({ user }) => {
  const { ui, user: userStore } = useStore()
  const [isLogoutOpen, setIsLogoutOpen] = useState(false)
  const router = useRouter()
  const { logout } = usePrivy()

  const username = userStore.account?.username

  const handleLogout = () => {
    logout()
    setIsLogoutOpen(false)
    setTimeout(() => {
      userStore.logout()
      router.push(DEFAULT_HOME_ROUTE)
    }, 350)
  }

  return (
    <DropDownRoot modal={false}>
      <DropDownTrigger asChild>
        <IconButton aria-label="Customise options">
          <Avatar
            round
            src={user.avatar?.url}
            size="small"
            username={username}
            css={{
              maxHeight: '32px',
              maxWidth: '32px'
            }}
          />
        </IconButton>
      </DropDownTrigger>
      <DropdownPortal>
        <DropdownMenuContent sideOffset={5}>
          <DropdownMenuItem onClick={() => router.push(`${AppRoutes.PROFILE}/${user.username}`)}>
            <Avatar
              round
              size="xxxsmall"
              src={user.avatar?.url}
              username={username}
              css={{
                maxHeight: '32px',
                maxWidth: '32px'
              }}
            />
            <Text subText type="body2">
              Profile
            </Text>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => ui.openRefferalModal()}>
            <Icon
              icon="Asterisk"
              css={{
                height: 18,
                width: 18
              }}
            />
            <Text subText type="body2">
              Referrals
            </Text>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => ui.openProfileSettingsModal()}>
            <Icon
              icon="Settings"
              css={{
                height: 18,
                width: 18
              }}
            />
            <Text subText type="body2">
              Settings
            </Text>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setIsLogoutOpen(true)}>
            <Icon
              icon="Logout"
              css={{
                height: 11,
                width: 18
              }}
            />
            <Text subText type="body2">
              Logout
            </Text>
          </DropdownMenuItem>
          <DropdownMenuSeparator />

          <ConnectedWallet />

          <DropdownMenuArrow />
        </DropdownMenuContent>
      </DropdownPortal>
      <WarningModal
        isOpen={isLogoutOpen}
        onIgnore={() => setIsLogoutOpen(false)}
        description="Are you sure you want to logout?"
        confirmButtonLabel="Yes"
        rejectButtonLabel="No"
        onConfirm={() => handleLogout()}
        onReject={() => setIsLogoutOpen(false)}
      />
    </DropDownRoot>
  )
}
export const ProfileDropDown = observer(_ProfileDropDown)
