import { ComponentProps } from '@stitches/react'
import { ConfirmPopupDialogActions } from 'common/ConfirmPopupDialog/ConfirmPopupDialogActions'
import { ConfirmPopupDialogNoButton } from 'common/ConfirmPopupDialog/ConfirmPopupDialogNoButton'
import { ConfirmPopupDialogYesButton } from 'common/ConfirmPopupDialog/ConfirmPopupDialogYesButton'
import { Modal } from 'common/Modal'
import { ModalContentDialog } from 'common/Modal/ModalContentDialog'
import { useStore } from 'lib/store'
import { observer } from 'mobx-react-lite'
import { Flex } from 'primitives/Flex'
import { Heading } from 'primitives/Heading'
import { Text } from 'primitives/Text'

type Props = ComponentProps<typeof Modal>

export const AgeDisclaimer: React.FC<Props> = observer(({ css }) => {
  const store = useStore()

  const handleEnter = () => {
    store.onboarding.acceptAgeDisclaimerModal()
  }
  const handleExit = () => {
    window.location.href = 'https://www.google.com'
  }

  // Do not render if the onboarding modal is not open
  if (!store.onboarding.showAgeDisclaimerModal) return null

  return (
    <Modal isOpen={store.onboarding.showAgeDisclaimerModal} onBackgroundClick={handleExit}>
      <ModalContentDialog
        css={{
          backgroundColor: '#2C2E35',
          '@lg': {
            borderRadius: '16px'
          }
        }}
      >
        <Flex
          column
          css={{
            alignItems: 'center',
            textAlign: 'center',
            padding: 15,
            gap: 10
          }}
        >
          <Heading as="h3">This website is only for consenting adults.</Heading>
          <Flex column css={{ maxWidth: '431px', marginTop: '20px' }}>
            <Text>
              By proceeding any further to this adult website, you are confirming that you are 18
              years old or at least the age of majority according to the law in your state, country,
              or jurisdiction. You are also agreeing that you will not permit any person(s) younger
              than the age of majority in your state, country, or jurisdiction have access to any
              material contained within this website.
            </Text>
            <Text>
              By continuing, you are giving your consent to view this website which contains nudity
              and depictions of sexually explicit activity.
            </Text>
          </Flex>

          <ConfirmPopupDialogActions>
            <ConfirmPopupDialogYesButton onClick={handleEnter}>Enter</ConfirmPopupDialogYesButton>
            <ConfirmPopupDialogNoButton onClick={handleExit}>Exit</ConfirmPopupDialogNoButton>
          </ConfirmPopupDialogActions>
        </Flex>
      </ModalContentDialog>
    </Modal>
  )
})
