import { Modal } from 'common/Modal'
import { IntroModalContent } from 'shared/IntroModalContent'
import { ModalContentDialog } from 'common/Modal/ModalContentDialog'
import { ModalCloseButton } from 'common/Modal/ModalCloseButton'
import { Button } from 'common/Button'
import { Icon } from 'common/Icon'
import { OnboardingModal } from 'components/modals/GettingStarted'
import { useStore } from 'lib/store'
import { observer } from 'mobx-react-lite'

const _AppInfoModal = () => {
  const { user, ui } = useStore()

  return (
    <>
      <Button
        icon
        theme="secondary"
        onClick={() => {
          ui.openGettingStartedModal()
        }}
      >
        <Icon icon="Stack" iconSize={18} />
      </Button>
      <Modal
        isOpen={ui.getingStartedModal}
        onBackgroundClick={() => ui.closeGettingStartedModal()}
        animation="fadeIn"
        backdrop="blurred"
      >
        <ModalContentDialog css={{ height: '100%', '@lg': { width: '450px' } }}>
          <ModalCloseButton onClick={() => ui.closeGettingStartedModal()} />
          {!user.isLoggedIn ? (
            <IntroModalContent onContinue={() => ui.closeGettingStartedModal()} />
          ) : (
            <OnboardingModal closeModal={() => ui.closeGettingStartedModal()} />
          )}
        </ModalContentDialog>
      </Modal>
    </>
  )
}

export const AppInfoModal = observer(_AppInfoModal)
