import { SnackBar } from 'common/SnackBar'
import snackBar from 'common/SnackBar/config'
import { toast } from 'components/common/Toast'
import { SEARCH, SearchResponse } from 'graphql/queries/search'
import { SearchQueryVariables, SortBy, SortDirection } from 'graphql/types'
import { useStore } from 'lib/store'
import { NSFW_EVENT } from 'lib/tracking/types'
import { query } from 'lib/urql/query'
import { Profile } from 'models/Profile'
import { useCallback, useEffect, useRef, useState } from 'react'

export const useSearchProfiles = (searchText?: string) => {
  const store = useStore()
  const [results, setResults] = useState<Profile[]>([])
  const [fetching, setFetching] = useState(false)
  const [cursor, setCursor] = useState<string>()
  const [hasNextPage, setHasNextPage] = useState(false)
  const [noResultsFound, setNoResultsFound] = useState(false)
  const lastQuery = useRef<string | undefined>(searchText)

  const search = useCallback(
    async (newQuery?: string) => {
      setFetching(true)

      const _query = newQuery || lastQuery.current!
      lastQuery.current = _query

      const { data, error } = await query<SearchResponse, SearchQueryVariables>(SEARCH, {
        query: _query,
        options: {
          limit: 10,
          lastRecord: newQuery ? undefined : cursor,
          sortBy: SortBy.CREATED_AT,
          sortDirection: SortDirection.DESC
        }
      })
      setFetching(false)

      store.analytics.track(NSFW_EVENT.SEARCH_QUERY, {
        query: _query,
        hasResults: data ? true : false
      })

      if (error) toast('Unable to search')

      if (data) {
        setResults((lastResults) => {
          const newResults = [
            ...(newQuery ? [] : lastResults),
            ...(data?.search?.items || []).map((profile) => store.profiles.load(profile))
          ]
          setNoResultsFound(!newResults.length)
          return newResults
        })
        setHasNextPage(!!data?.search?.hasNextPage)
        setCursor(data?.search?.cursor ?? undefined)
      }
    },
    [store.profiles, cursor, lastQuery]
  )

  const clear = () => {
    lastQuery.current = undefined
    setResults([])
    setCursor(undefined)
    setHasNextPage(false)
    setNoResultsFound(false)
  }

  const statusText = () => {
    switch (true) {
      case noResultsFound:
        return 'No results'
      case !!results?.length:
        return undefined
      default:
        return 'Loading...'
    }
  }

  // Initial fetch if searchText is given to the hook
  useEffect(() => {
    if (!searchText) clear()
    else if (searchText !== lastQuery.current) search(searchText)
  }, [searchText, search, lastQuery])

  return {
    results,
    fetching,
    hasNext: hasNextPage && lastQuery.current,
    loadMore: search,
    statusText: statusText()
  }
}
