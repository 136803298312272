import { Button } from 'common/Button'
import { Drawer, DrawerHeader, DrawerHeaderContent } from 'common/Drawer'
import { BellWhite, Icon } from 'common/Icon'
import { IconButtonWithCount } from 'common/IconButtonWithCount'
import { Spinner } from 'common/Spinner'
import { WarningModal } from 'containers/WarningModal/WarningModal'
import { useStore } from 'lib/store'
import { styled } from 'lib/theme'
import { observer } from 'mobx-react-lite'
import { Flex } from 'primitives/Flex'
import { Heading } from 'primitives/Heading'
import { useState } from 'react'
import { FeedEnd } from 'shared/FeedItem/FeedEnd'
import { Notifications } from './Components/Notifications'

const MainContainer = styled(Flex, {
  position: 'relative',
  flexDirection: 'column',
  width: '100%',
  height: '100%'
})

export const _NotificationsDrawer = () => {
  const { ui, user } = useStore()
  const [isClearNotifWarningOpen, setIsClearNotifWarningOpen] = useState(false)

  const opened = ui.showNotifications
  const unreadCount = user.notifications.unreadNotifications

  const clearNotifications = () => {
    setIsClearNotifWarningOpen(false)
    return user.notifications.clearNotifications()
  }

  const handleToggleNotifications = () => {
    if (ui.showNotifications) {
      ui.closeNotifications()
    } else {
      ui.openNotifications()
      user.notifications.fetchUnread()
      user.notifications.fetch()
    }
  }

  const resolveContent = () => {
    switch (true) {
      case user.notifications.loading:
        return (
          <Flex full center>
            <Spinner css={{ width: 36, height: 36 }} />
          </Flex>
        )
      case user.notifications.items.length > 0:
        return <Notifications />
      default:
        return <FeedEnd />
    }
  }

  return (
    <>
      <IconButtonWithCount count={unreadCount} onClick={handleToggleNotifications}>
        <BellWhite />
      </IconButtonWithCount>
      <Drawer
        opened={opened}
        onClose={() => ui.closeNotifications()}
        overlayOpacity={0}
        withOverlay={!isClearNotifWarningOpen}
        size="420px"
      >
        <MainContainer>
          <DrawerHeader
            sticky
            // Line up the close button with the more options buttons.
            css={{ paddingRight: 10 }}
            onClose={() =>
              ui.showNotifications ? ui.closeNotifications() : ui.openNotifications()
            }
          >
            <DrawerHeaderContent css={{ justifyContent: 'space-between' }}>
              <Heading as="h4">Notifications {unreadCount > 0 ? `[${unreadCount}]` : ''}</Heading>
              {/* TODO: radix dropdown menu of actions (like linear) */}
              <Button
                icon
                css={{
                  '&:hover': {
                    '& .primary.fill': {
                      fill: '$red100 !important'
                    }
                  }
                }}
                onClick={() => setIsClearNotifWarningOpen(true)}
              >
                <Icon icon="ClearList" />
              </Button>
            </DrawerHeaderContent>
          </DrawerHeader>
          {resolveContent()}
        </MainContainer>
      </Drawer>

      <WarningModal
        isOpen={isClearNotifWarningOpen}
        lockScrollbar={false}
        title="Are you sure you want to delete all notifications?"
        description="This will delete all notifications in your inbox"
        confirmButtonLabel="Delete all"
        rejectButtonLabel="Cancel"
        onConfirm={clearNotifications}
        onIgnore={() => setIsClearNotifWarningOpen(false)}
      />
    </>
  )
}

export const NotificationsDrawer = observer(_NotificationsDrawer)
