import { Flex } from 'primitives/Flex'
import { usePrevious, useWindowSize } from 'react-use'

type BannerLayoutProps = {}
export const BannerLayout: React.FC<BannerLayoutProps> = ({ children }) => {
  const { width: windowWidth } = useWindowSize()
  return (
    <Flex
      css={{
        width: 'auto',
        minHeight: '40px',
        justifyContent: 'center'
      }}
    >
      {children}
    </Flex>
  )
}
