import { Network } from '@nsfw-app/crypto'
import { Button } from 'common/Button'
import { Icon } from 'common/Icon'
import { PopupDialog } from 'common/PopupDialog'
import { PopupDialogContent } from 'common/PopupDialog/PopupDialogContent'
import { mapBadgeNamesByChainId } from 'config/chainsMap'
import { useStore } from 'lib/store'
import { NetworkListItem } from 'lib/web3/chains'
import { toJS } from 'mobx'

import { List } from 'primitives/List'
import { ListItem } from 'primitives/ListItem'
import { Text } from 'primitives/Text'
import { useEffect } from 'react'

// import { Icon } from 'common/Icon'
// import { tokenToIconMap } from 'config/chainsMap'

interface MobileDialogProps {
  isOpen: boolean
  onIgnore: () => void
  onClick: (chainId: Network) => void
  networks: NetworkListItem[]
}

export const NetworkSelectorDialog: React.FC<MobileDialogProps> = ({
  isOpen,
  onClick,
  onIgnore,
  networks
}) => {
  const store = useStore()

  useEffect(() => {
    if (store.network.walletNetwork?.id) {
      console.log('----- WE HAVE A WALLET NETWORK -----', store.network.walletNetwork.id)
      console.log('----- WE HAVE A WALLET NETWORK -----', store.network.walletNetwork.id)
      console.log('----- WE HAVE A WALLET NETWORK -----', store.network.walletNetwork.id)
      console.log('----- WE HAVE A WALLET NETWORK -----', store.network.walletNetwork.id)
    }
  }, [store.network.walletNetwork?.id])

  return (
    <PopupDialog isOpen={isOpen} onIgnore={onIgnore} backdrop="blurred">
      <PopupDialogContent>
        <Text type="body3" css={{ marginBottom: 24 }}>
          Please select a supported network
        </Text>
        <List
          type="grouped"
          css={{ [`& ${ListItem}`]: { padding: 0, margin: 0 }, paddingLeft: '0' }}
        >
          {networks.map(({ id, name }) => (
            <ListItem key={`chain-${id}`}>
              <Button web3Wallet onClick={() => onClick(id)}>
                <Text type="body2" css={{ whiteSpace: 'nowrap' }}>
                  {name}
                </Text>
                <Icon icon={mapBadgeNamesByChainId[id]} css={{ height: 20, width: 20 }} />
              </Button>
            </ListItem>
          ))}
        </List>
      </PopupDialogContent>
    </PopupDialog>
  )
}
