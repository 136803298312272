import { Link } from 'common/Link'
import { keyframes, styled } from 'lib/theme'

const background = keyframes({
  '0%': {
    backgroundPosition: '200% center',
  },
  '25%': {
    backgroundPosition: '150% center',
  },
  '50%': {
    backgroundPosition: '100% center',
  },
  '75%': {
    backgroundPosition: '50% center',
  },
  '100%': {
    backgroundPosition: '0% center',
  },
})
export const GradientNavLink = styled(Link, {
  background: 'radial-gradient(circle, #9CA0CC,#F3E289, #CB5FC7, #F8CB2A)',
  backgroundSize: '200%',
  textShadow: '0 0 1rem #ffffff15',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: `${background} 7s linear infinite`,
})
