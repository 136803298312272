import { styled } from 'lib/theme'
import { ComponentProps } from 'lib/theme'
import { DrawerCloseButton } from './DrawerCloseButton'
import { Flex } from 'primitives/Flex'

const Header = styled(Flex, {
  background: '$gray100',
  height: 69,
  minHeight: 69,
  alignItems: 'center',
  borderBottom: '1px solid $gray500',
  // less padding on the right side to account for the close button svg white space. Ideally it should have none.
  padding: 16,
  paddingRight: 6,
  variants: {
    sticky: {
      true: {
        position: 'sticky',
        top: 0,
        zIndex: 10,
      },
    },
  },
})

export const DrawerHeaderContent = styled(Flex, {
  width: '100%',
  height: '100%',
  alignItems: 'center',
})

interface DrawerHeaderProps extends ComponentProps<typeof Header> {
  onClose: () => void
}

export const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  children,
  onClose,
  css,
  sticky,
}) => {
  return (
    <Header css={css} className='drawer-header' sticky={sticky}>
      {children}
      <DrawerCloseButton onClose={onClose} />
    </Header>
  )
}
