import { Icon } from 'common/Icon'
import { formatAddress } from 'common/Web3/formatAddress'
import { mapBadgeNamesByChainId } from 'config/chainsMap'
import { useStore } from 'lib/store'
import { styled, ComponentProps } from 'lib/theme'
import { observer } from 'mobx-react-lite'
import { Button } from 'primitives/Button'
import { Flex } from 'primitives/Flex'
import { Text } from 'primitives/Text'
import { Box } from 'primitives/index'

const WalletIndicatorButton = styled(Button, {
  borderRadius: 8,
  padding: '7px 16px',
  color: '$gray800',
  '&:disabled': {
    cursor: 'default'
  }
})

type Props = ComponentProps<typeof WalletIndicatorButton>

export const ConnectedWallet: React.FC<Props> = observer(({ css }) => {
  const store = useStore()

  const network = store.network.walletNetwork

  if (!network) return null

  return (
    <WalletIndicatorButton
      css={{
        borderRadius: 0,
        textTransform: 'none',
        padding: '16px',
        justifyContent: 'start',
        gap: '8px'
        // '& .primary.fill': {
        //   fill: store.wallets.connected?.isConnected ? '$voilet200' : '$red100 '
        // },
        // '&:hover': {
        //   p: { color: '$gray900' },
        //   '& .primary.fill': {
        //     fill: store.wallets.connected?.isConnected ? '$gray900' : '$red100 '
        //   }
        // },
        // ...css
      }}
      // onClick={handleClick}
    >
      <Flex column>
        <Flex>
          <Icon icon={mapBadgeNamesByChainId[network.id]} css={{ height: 12, width: 12 }} />
          &nbsp; &nbsp;
          <Text
            type="body3"
            subText
            // css={{
            //   color: store.wallets.connected?.isConnected ? '$voilet200' : ' $red100 !important '
            // }}
          >
            {network.name}
          </Text>
        </Flex>
        <Flex>
          <Text
            type="body3"
            subText
            // css={{
            //   color: store.wallets.connected?.isConnected ? '$voilet200' : ' $red100 !important '
            // }}
          >
            Wallet:{' '}
            {store.wallets.connected?.isConnected
              ? formatAddress(store.wallets.connected?.address)
              : 'Connect wallet'}{' '}
          </Text>
        </Flex>
      </Flex>
    </WalletIndicatorButton>
  )
})
