import { Button } from 'common/Button'
import { Icon } from 'common/Icon'
import { Link } from 'common/Link'
import { Box } from 'primitives/Box'
import { Flex } from 'primitives/Flex'
import { Text } from 'primitives/Text'
import { ComponentProps } from 'react'
import { ActionProps } from '../Actions'

interface ActionItemProps extends ComponentProps<typeof Box> {
  label: ActionProps
  onClick: () => void
  isChecked: boolean
  hasExternalLink?: boolean
}

export const ActionItem: React.FC<ActionItemProps> = ({
  label,
  onClick,
  isChecked,
  hasExternalLink,
  css,
}) => {
  return (
    <Button
      theme='secondary'
      disabled={isChecked}
      css={{
        border: 'none',
        borderRadius: '0px',
        textTransform: 'unset',
        cursor: isChecked ? 'not-allowed' : 'pointer',
        ...css,
      }}
      onClick={onClick}
    >
      <Flex
        css={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Flex center css={{ gap: '20px' }}>
          <Icon
            icon='Check'
            css={{
              width: 25,
              height: 25,
              '& .primary.fill': {
                fill: isChecked ? '$voilet100' : '$gray100',
              },
              '& .primary.stroke': {
                stroke: isChecked ? '$gray900' : '$gray100',
              },
            }}
          />

          <Flex column css={{ textAlign: 'start' }}>
            <Text
              type='body2'
              css={{
                textDecoration: isChecked ? 'line-through' : 'none',
                color: isChecked ? '$gray700' : '$gray900',
              }}
            >
              {label.title}
            </Text>
            {label.subTitle && !isChecked && (
              <Text
                type='body4'
                css={{
                  color: isChecked ? '$gray700' : '$gray800',
                }}
              >
                {label.subTitle}
              </Text>
            )}
            {/* TODO: Discuss external links */}
            {/* {!hasExternalLink && (
              <Link
                rel='noopener'
                target='blank'
                href='https://explodingtopics.com/'
                nounderline
                theme='primary'
                css={{
                  gap: '3px',
                }}
              >
                <Text type='body3' css={{ color: '$voilet100' }}>
                  How to
                </Text>
                <Icon icon='ExternalLink' />
              </Link>
            )} */}
          </Flex>
        </Flex>
        {!isChecked && (
          <Flex
            column
            center
            css={{
              height: '24px',
              width: '24px',
            }}
          >
            <Icon
              icon='Chevron'
              css={{
                background: 'unset',
                '& .primary.stroke': {
                  stroke: '$gray700',
                },
              }}
            />
          </Flex>
        )}
      </Flex>
    </Button>
  )
}
