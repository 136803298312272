import { ComponentProps } from '@stitches/react'
import { Icon, KnownIcon } from 'common/Icon'
import { Link } from 'common/Link'
import { FOOTER_HEIGHT } from 'config'
import { FOOTER_LINKS, SOCIAL_LINKS } from 'config/footerLinks'
import { utmCampaign } from 'lib/helpers'
import appVersion from 'lib/nextjs/appVersion'
import { useStore } from 'lib/store'
import { theme } from 'lib/theme'
import { NSFW_EVENT } from 'lib/tracking/types'
import { Anchor } from 'primitives/Anchor'
import { Flex } from 'primitives/Flex'
import { Footer as FooterPrimitive } from 'primitives/Footer'
import { List } from 'primitives/List'
import { ListItem } from 'primitives/ListItem'
import { Nav } from 'primitives/Nav'
import { Text } from 'primitives/Text'

export type FooterProps = ComponentProps<typeof FooterPrimitive>

export const Footer: React.FC<FooterProps> = ({ css }) => {
  const store = useStore()
  const handleSocialLinksTracking = (icon: KnownIcon) => {
    switch (icon) {
      case 'Discord':
        return store.analytics.track(NSFW_EVENT.FOOTER_DISCORD_CLICK)
      case 'Twitter':
        return store.analytics.track(NSFW_EVENT.FOOTER_TWITTER_CLICK)
      case 'Telegram':
        return store.analytics.track(NSFW_EVENT.FOOTER_TELEGRAM_CLICK)
      default:
        return
    }
  }
  const handleFooterLinksTracking = (name: string) => {
    switch (name) {
      case 'Privacy Policy':
        return store.analytics.track(NSFW_EVENT.FOOTER_PRIVACY_POLICY_CLICK)
      default:
        return
    }
  }
  return (
    <FooterPrimitive
      css={{
        backgroundColor: theme.colors.gray100,
        borderTop: `1px solid ${theme.colors.gray400}`,
        width: '100%',
        ...css
      }}
    >
      <Flex
        css={{
          height: FOOTER_HEIGHT,
          display: 'flex',
          alignItems: 'center',
          maxWidth: theme.container.maxWidth,
          width: '100%',
          margin: 'auto',
          transition: 'all 200ms ease',
          justifyContent: 'space-between',
          '@md': {
            padding: '0 20px'
          }
        }}
      >
        {' '}
        <Text
          type="body4"
          css={{
            display: 'none',
            '@lg': {
              display: 'block'
            }
          }}
        >
          v{appVersion()}
        </Text>
        <List
          horizontal
          css={{
            gap: '15px',
            width: 'auto',
            padding: '10px',
            '@lg': {
              width: '220px'
            }
          }}
        >
          {SOCIAL_LINKS.map(({ icon, href }) => (
            <Anchor
              key={href}
              onClick={() => handleSocialLinksTracking(icon)}
              rel="noopener noreferrer"
              target="_blank"
              href={href}
              title={href}
            >
              <Icon
                icon={icon}
                css={{
                  height: '22px',
                  width: '22px',
                  '& .primary.fill': {
                    fill: theme.colors.gray800
                  },
                  ':hover': {
                    '& .primary.fill': {
                      fill: theme.colors.gray900
                    }
                  }
                }}
              />
            </Anchor>
          ))}
        </List>
        <Nav>
          <List horizontal css={{ gap: '15px', listStyleType: 'none' }}>
            {FOOTER_LINKS.map(({ name, href }) => (
              <ListItem key={href} onClick={() => handleFooterLinksTracking(name)}>
                <Link href={href} theme="text">
                  <Text type="body4">{name}</Text>
                </Link>
              </ListItem>
            ))}
          </List>
        </Nav>
      </Flex>
    </FooterPrimitive>
  )
}
