// Sourced: https://github.com/JackHamer09/web3-avatar/blob/main/js/index.ts
import { usePrivy } from '@privy-io/react-auth'
import { ethers, keccak256, toUtf8Bytes } from 'ethers'
import React from 'react'

export function getGradientColors(address: string) {
  const seedArr = address?.match(/.{1,7}/g)?.splice(0, 5)
  const colors: string[] = []

  // biome-ignore lint/complexity/noForEach: <explanation>
  seedArr?.forEach((seed) => {
    let hash = 0
    for (let i = 0; i < seed.length; i += 1) {
      hash = seed.charCodeAt(i) + ((hash << 5) - hash)
      hash = hash & hash
    }

    const rgb = [0, 0, 0]
    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 255
      rgb[i] = value
    }
    colors.push(`rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`)
  })

  return colors
}

/**
 * Generate a deterministic wallet address from a given seed
 */
const addressFromSeed = (seed: string) => {
  // Normalize the input
  const normalised = seed.toLowerCase()
  // Hash the normalized input to generate a fixed-size output
  const privateKey = keccak256(toUtf8Bytes(normalised))

  const wallet = new ethers.Wallet(privateKey)

  return wallet.address
}

export const DefaultAvatar = ({
  seed,
  css
}: { seed?: string; css?: { width?: number; height?: number } }) => {
  let address = ''
  if (seed) {
    address = addressFromSeed(seed ?? 'default')
  }

  const colors = getGradientColors(address)

  return (
    <div
      style={{
        // Base
        boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.1)',
        backgroundColor: colors[0],
        backgroundImage: `
          radial-gradient(at 66% 77%, ${colors[1]} 0px, transparent 50%),
          radial-gradient(at 29% 97%, ${colors[2]} 0px, transparent 50%),
          radial-gradient(at 99% 86%, ${colors[3]} 0px, transparent 50%),
          radial-gradient(at 29% 88%, ${colors[4]} 0px, transparent 50%)
        `,
        // Overrides: FIXME: This needs to be configurable and consistent for the areas this is shown
        // position: 'absolute',
        top: '50%',
        left: '50%',
        // transform: 'translate(0%, -23%) scale(1)',
        width: '100%',
        height: '100%',
        ...css
      }}
    />
  )
}
