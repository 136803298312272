import { ComponentProps } from '@stitches/react'
import { ConfirmPopupDialog } from 'common/ConfirmPopupDialog'
import { ConfirmPopupDialogActions } from 'common/ConfirmPopupDialog/ConfirmPopupDialogActions'
import { ConfirmPopupDialogDescription } from 'common/ConfirmPopupDialog/ConfirmPopupDialogDescription'
import { ConfirmPopupDialogNoButton } from 'common/ConfirmPopupDialog/ConfirmPopupDialogNoButton'
import { ConfirmPopupDialogTitle } from 'common/ConfirmPopupDialog/ConfirmPopupDialogTitle'
import { ConfirmPopupDialogYesButton } from 'common/ConfirmPopupDialog/ConfirmPopupDialogYesButton'

export const WarningModal: React.FC<ComponentProps<typeof ConfirmPopupDialog>> =
  ({
    onConfirm,
    onReject,
    onIgnore,
    isOpen,
    rejectIsLoading,
    confirmIsLoading,
    title,
    description,
    confirmButtonLabel,
    rejectButtonLabel,
    css,
    ...modalProps
  }) => (
    <ConfirmPopupDialog
      animation='fadeIn'
      onIgnore={() => onIgnore()}
      isOpen={isOpen}
      css={css}
      {...modalProps}
    >
      <ConfirmPopupDialogTitle>{title}</ConfirmPopupDialogTitle>
      <ConfirmPopupDialogDescription>
        {description}
      </ConfirmPopupDialogDescription>
      <ConfirmPopupDialogActions>
        <ConfirmPopupDialogYesButton
          onClick={() => onConfirm?.()}
          loading={confirmIsLoading}
        >
          {confirmButtonLabel}
        </ConfirmPopupDialogYesButton>
        <ConfirmPopupDialogNoButton
          onClick={() => (onReject ? onReject() : onIgnore?.())}
          loading={rejectIsLoading}
        >
          {rejectButtonLabel}
        </ConfirmPopupDialogNoButton>
      </ConfirmPopupDialogActions>
    </ConfirmPopupDialog>
  )
