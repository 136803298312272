import { ComponentProps } from '@stitches/react'
import { Flex } from 'primitives/Flex'
import { Heading } from 'primitives/Heading'
import { Text } from 'primitives/Text'
import { styled, theme } from 'lib/theme'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { ActionItem } from './ActionItem'
import { useStore } from 'lib/store'
import { useRouter } from 'next/router'
import { creatorActionClick, creatorTitle, fanActionClick, fanTitle } from './Actions'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Spinner } from 'common/Spinner'

const Progress = styled(ProgressPrimitive.Root, {
  position: 'relative',
  overflow: 'hidden',
  background: '$gray400',
  borderRadius: '9999px',
  width: '50%',
  height: 8,
  minHeight: 8,
  transform: 'translateZ(0)'
})

const ProgressIndicator = styled(ProgressPrimitive.Indicator, {
  background: 'linear-gradient(73.28deg, #2f1b52 6.51%, #7230FF 88.45%)',
  borderRadius: '9999px',
  width: '100%',
  height: '100%',
  transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)'
})

type StepWrapperProps = ComponentProps<typeof Flex>

const ActionsWrapper: React.FC<StepWrapperProps> = ({ children }) => {
  return (
    <Flex column full center>
      <Flex
        column
        center
        css={{
          textAlign: 'center',
          marginBottom: '30px',
          borderRadius: '8px',
          padding: '0 10px',
          height: '100%',
          width: '100%',
          '>:not(:last-child)': {
            borderBottom: `1px solid ${theme.colors.gray500}`
          },
          '>:nth-child(1)': {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px'
          },
          '>:last-child': {
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px'
          },

          '@md': {
            minHeight: '100%',
            maxWidth: '620px'
          }
        }}
      >
        {children}
      </Flex>
    </Flex>
  )
}

interface OnboardingModalProps extends ComponentProps<typeof Flex> {
  closeModal: () => void
}

export const OnboardingModal: React.FC<OnboardingModalProps> = observer(({ closeModal }) => {
  const store = useStore()
  const router = useRouter()

  const { isFetching } = store.user.onboarding || {}

  const totalActions = store.onboarding.gettingStartedItems.length
  const checkedActions = store.onboarding.gettingStartedItems.filter(
    (action) => action.value
  ).length
  const percentage = (checkedActions / totalActions) * 100

  useEffect(() => {
    store.user.fetchOnboarding()
  }, [store.user])

  return (
    <Flex column css={{ justifyContent: 'center', minHeight: 'auto' }}>
      {isFetching ? (
        <Flex full center>
          <Spinner />
        </Flex>
      ) : (
        <>
          <Flex column center css={{ gap: '5px', margin: '25px 0' }}>
            <Heading css={{ marginBottom: '20px' }} as="h4">
              Getting Started
            </Heading>

            <Progress value={percentage}>
              <ProgressIndicator css={{ transform: `translateX(-${100 - percentage}%)` }} />
            </Progress>

            <Text type="body3" subText>
              Onboarding Progress
            </Text>
          </Flex>

          <ActionsWrapper>
            {store.onboarding.gettingStartedItems
              // .sort((a, b) => (a.value === b.value ? 0 : a.value ? -1 : 1))
              .map(({ key, value }, i) => (
                <ActionItem
                  label={store.user.isCreator ? creatorTitle(key) : fanTitle(key)}
                  onClick={
                    store.user.isCreator
                      ? creatorActionClick(key, store, router, closeModal)
                      : fanActionClick(key, store, router, closeModal)
                  }
                  isChecked={value}
                  hasExternalLink={false}
                  key={i + key}
                />
              ))}
          </ActionsWrapper>
        </>
      )}
    </Flex>
  )
})
