import { ComponentProps } from '@stitches/react'
import { Profile } from 'common/Icon'
import { DefaultAvatar } from 'components/DefaultAvatar'
import { styled, theme } from 'lib/theme'
import { Box } from 'primitives/Box'
import { Image, ImageProps } from 'primitives/Image'

export const AvatarWrapper = styled(Box, {
  position: 'relative',
  borderRadius: '100%',
  variants: {
    size: {
      xxxxsmall: {
        height: '14px',
        width: '14px',
        minHeight: '14px',
        minWidth: '14px'
      },
      xxxsmall: {
        height: '16px',
        width: '16px',
        minHeight: '16px',
        minWidth: '16px'
      },
      xxsmall: {
        height: '20px',
        width: '20px',
        minHeight: '20px',
        minWidth: '20px'
      },
      xsmall: {
        height: '28px',
        width: '28px',
        minHeight: '28px',
        minWidth: '28px'
      },
      small: {
        height: '38px',
        width: '38px',
        minHeight: '38px',
        minWidth: '38px'
      },
      medium: {
        height: '48px',
        width: '48px',
        minHeight: '48px',
        minWidth: '48px'
      },
      large: {
        height: '56px',
        width: '56px',
        minHeight: '56px',
        minWidth: '56px'
      },
      xlarge: {
        height: '92px',
        width: '92px',
        minHeight: '92px',
        minWidth: '92px'
      },
      xxlarge: {
        height: '300px',
        width: '100%',
        minHeight: '300px',
        minWidth: '100%'
      }
    },
    status: {
      active: {
        border: '2px solid transparent',
        backgroundImage: theme.gradients.blue
      },
      inactive: {
        border: `2px solid ${theme.colors.gray600}`,
        backgroundImage: 'none'
      }
    }
  },
  defaultVariants: {
    size: 'medium'
  }
})

export interface AvatarProps extends ComponentProps<typeof AvatarWrapper>, Pick<ImageProps, 'alt'> {
  username?: string
  src?: string
  round?: boolean
  children?: React.ReactNode
}

export const Avatar: React.FC<AvatarProps> = ({
  username,
  src,
  alt,
  size = 'medium',
  round = true,
  status,
  children,
  onClick,
  css
}) => {
  return (
    <AvatarWrapper status={status} size={size} css={css} onClick={onClick}>
      <Image
        src={src}
        alt={alt}
        css={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          borderRadius: round ? '50%' : '10px',
          objectFit: 'cover',
          overflow: 'hidden'
        }}
        fallback={
          username ? (
            <DefaultAvatar seed={username} />
          ) : (
            <Profile css={{ width: '100%', height: '100%', path: { fill: '$gray800' } }} />
          )
        }
      />
      {children}
    </AvatarWrapper>
  )
}
