import dynamic from 'next/dynamic'
import { useStore } from 'lib/store/useStore'
import { observer } from 'mobx-react-lite'
import type { ApplicationErrorModalProps } from 'shared/Errors/ApplicationErrorModal'
import { AgeDisclaimer } from 'components/modals/AgeDisclaimer'
import { useRouter } from 'next/router'

const CreatorOnboarding = dynamic(
  () => import('components/modals/CreatorOnbarding').then((mod) => mod.CreatorOnboarding),
  { ssr: false }
)

const PaymentModal = dynamic(
  () => import('components/modals/PaymentModal/PaymentModal').then((mod) => mod.PaymentModal),
  { ssr: false }
)

const WithdrawalModal = dynamic(
  () => import('containers/WithdrawalModal/WithdrawalModal').then((mod) => mod.WithdrawalModal),
  { ssr: false }
)

const ApplicationErrorModal = dynamic<ApplicationErrorModalProps>(
  () => import('shared/Errors/ApplicationErrorModal').then((mod) => mod.ApplicationErrorModal),
  { ssr: false }
)

const ProfileSettingsModal = dynamic(
  () => import('containers/ProfileSettingsModal').then((mod) => mod.ProfileSettingsModal),
  { ssr: false }
)

const PostModal = dynamic(() => import('containers/PostModal').then((mod) => mod.PostModal), {
  ssr: false
})

const RefferalsModal = dynamic(
  () => import('shared/RefferalsModal').then((mod) => mod.RefferalsModal),
  { ssr: false }
)

const ClaimRewardsModal = dynamic(
  () => import('containers/ClaimRewardModal/ClaimRewardModal').then((mod) => mod.ClaimRewardsModal),
  { ssr: false }
)

const CreatePostModal = dynamic(
  () => import('containers/CreatePostModal').then((mod) => mod.CreatePostModal),
  { ssr: false }
)

const ChatDrawers = dynamic(() => import('containers/ChatDrawers').then((mod) => mod.ChatDrawers), {
  ssr: false
})

// Paths that should not show the age disclaimer
const BANNED_PATHS = ['/confirm-email']

export const GlobalModals = observer(() => {
  const store = useStore()
  const router = useRouter()

  const currentPath = router.pathname
  const isBanned = BANNED_PATHS.some((path) => currentPath.includes(path))

  return (
    <>
      {!store.user.isLoggedIn && !isBanned && (
        <>
          <AgeDisclaimer />
        </>
      )}
      {store.user.isLoggedIn && (
        <>
          <PaymentModal />
          <ProfileSettingsModal />
          <RefferalsModal />
          <ChatDrawers />
          <ClaimRewardsModal />
          <CreatorOnboarding />
        </>
      )}
      {store.user.isCreator && (
        <>
          <CreatePostModal />
          <WithdrawalModal />
        </>
      )}
      {store.ui.showPostModal && <PostModal />}
      {store.ui.showAppErrorModal && (
        <ApplicationErrorModal
          error={store.ui.appError}
          isOpen={store.ui.showAppErrorModal}
          onClose={() => store.ui.closeAppErrorModal()}
        />
      )}
    </>
  )
})
