import { FeedType } from 'graphql/types'
import { useStore } from 'lib/store'
import { Profile } from 'models/Profile'
import { MouseEvent, useCallback } from 'react'

export const useToggleFollowProfile = (profile?: Profile) => {
  const store = useStore()

  const toggleFollowProfile = useCallback(
    async (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      if (store.ui.handleGatedAction() || !profile) return { success: false }

      const button = e.target as HTMLButtonElement
      button.disabled = true
      await profile.toggleFollowProfile()
      button.disabled = false
      store.feed.resetFeeds(FeedType.FOLLOWING)

      return { success: true, following: profile.isFollowing }
    },
    [store.ui, store.feed, profile]
  )

  return {
    toggleFollowProfile
  }
}
