import { Burger, BurgerProps, Drawer } from '@mantine/core'
import { useDrag, useGesture } from '@use-gesture/react'
import { Icon, KnownIcon } from 'common/Icon'
import { Link } from 'common/Link'
import { AppRoutes, SOCIAL_LINKS } from 'config'
import { AccountType } from 'graphql/types'
import { utmCampaign } from 'lib/helpers'
import { useStore } from 'lib/store'
import { styled, theme } from 'lib/theme'
import { NSFW_EVENT } from 'lib/tracking/types'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { Anchor } from 'primitives/Anchor'
import { Flex } from 'primitives/Flex'
import { Text } from 'primitives/Text'
import { useEffect, useRef } from 'react'
import { GradientNavLink } from 'shared/GradientNSFWLink'

// The xAxis "edge" that will disable the default touchstart event
const MOBILE_NAV_GESTURE_EDGE = 25
const MOBILE_TOP_NAV_HEIGHT = 56

const MainContainer = styled(Flex, {
  flexDirection: 'row',
  position: 'absolute',
  padding: '0 25px 50px 30px ',
  bottom: 0,
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'flex-end'
})

const LeftMenuItems = styled(Flex, {
  flexDirection: 'column',
  gap: '30px',
  marginBottom: '8px'
})

const RightMenuItems = styled(Flex, {
  flexDirection: 'column',
  gap: '20px',
  p: { fontSize: '1.525em' },
  a: { alignItems: 'flex-end' }
})

interface BurgerNavProps extends BurgerProps {}

export const _BurgerNav = () => {
  const store = useStore()
  const opened = store.ui.showBurgerMenu

  const userProfileUrl = `${AppRoutes.PROFILE}/${store.user.account?.username}`
  const isLoggedIn = store.user.isLoggedIn
  const isCreator = store.user.accountType === AccountType.CREATOR
  const { asPath } = useRouter()
  const appContainerRef = useRef<HTMLElement | null>(null)

  const handleSocialLinksTracking = (icon: KnownIcon) => {
    switch (icon) {
      case 'Discord':
        return store.analytics.track(NSFW_EVENT.FOOTER_DISCORD_CLICK)
      case 'Twitter':
        return store.analytics.track(NSFW_EVENT.FOOTER_TWITTER_CLICK)
      case 'Telegram':
        return store.analytics.track(NSFW_EVENT.FOOTER_TELEGRAM_CLICK)
      default:
        return
    }
  }

  const handleMenuItemLinkClick = () => store.ui.closeBurgerMenu()

  // Disable mobile browser gesture back/forward
  useEffect(() => {
    if (!appContainerRef.current) {
      appContainerRef.current = window.document.querySelector('#__next')
    }

    const handleNativeGesture = (e: any) => {
      // is not near **right** edge of view, or the menu is opened, exit
      if (!e.pageX || e.pageX < window.innerWidth - MOBILE_NAV_GESTURE_EDGE || opened) {
        return
      }
      // prevent swipe to navigate back gesture
      e.preventDefault()
    }

    // Note, disabling the event via use-gesture onDrag or onDragStart does not seem to work..
    // defining native event to handle this.
    appContainerRef.current?.addEventListener('touchstart', handleNativeGesture)
    return () => {
      appContainerRef.current?.removeEventListener('touchstart', handleNativeGesture)
    }
  }, [opened])

  useGesture(
    {
      onDrag: ({ initial, movement }) => {
        // is not near right edge of view, or the menu is open, exit
        if (initial[0] >= window.innerWidth - MOBILE_NAV_GESTURE_EDGE) {
          if (movement[0] < -15) {
            store.ui.openBurgerMenu()
          }
        }
      }
    },
    { eventOptions: { capture: true }, target: appContainerRef }
  )

  const bindDrag = useDrag(({ movement }) => {
    if (movement[0] > 50) {
      store.ui.closeBurgerMenu()
    }
  }, {})

  return (
    <>
      <Burger
        className="burger-nav"
        opened={opened}
        onClick={(ev) => {
          ev.stopPropagation()

          store.ui.showBurgerMenu ? store.ui.closeBurgerMenu() : store.ui.openBurgerMenu()
        }}
        title={opened ? 'Close navigation' : 'Open navigation'}
        color={theme.colors.gray900.toString()}
        size={18}
        styles={{
          root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            '&:focus': {
              outline: 0
            }
          }
        }}
      />
      <Drawer
        {...bindDrag()}
        opened={opened}
        onClose={() => store.ui.closeBurgerMenu()}
        withOverlay={false}
        trapFocus={false}
        padding="xl"
        size="100%"
        position="right"
        zIndex={Number(theme.zIndices.modal.value)}
        sx={() => ({
          '.mantine-Drawer-drawer': {
            backgroundColor: theme.colors.gray100.value,
            padding: '0',
            display: 'flex',
            flexDirection: 'column',
            //Lower down the drawer so the Mobile Top Nav can be seen
            top: `${MOBILE_TOP_NAV_HEIGHT}px`
          },
          '.mantine-Drawer-closeButton': {
            margin: '16px',
            height: '28px',
            width: '28px',
            svg: {
              height: '28px',
              width: '28px'
            }
          },
          '.mantine-Drawer-header': {
            display: 'none'
          }
        })}
      >
        <MainContainer>
          <LeftMenuItems>
            {SOCIAL_LINKS.map(({ icon, href }) => (
              <Anchor
                css={{ border: 'none !important' }}
                key={href}
                onClick={() => handleSocialLinksTracking(icon)}
                rel="noopener noreferrer"
                target="_blank"
                href={href}
              >
                <Icon
                  icon={icon}
                  css={{
                    height: '22px',
                    width: '22px',
                    '& .primary.fill': {
                      fill: theme.colors.gray800
                    },
                    ':active': {
                      '& .primary.fill': {
                        fill: theme.colors.gray900
                      }
                    }
                  }}
                />
              </Anchor>
            ))}
          </LeftMenuItems>

          <RightMenuItems css={{ a: { justifyContent: 'end' }, p: { color: '$gray800' } }}>
            <Link
              nounderline
              href={AppRoutes.SEARCH}
              active={asPath === AppRoutes.SEARCH}
              onClick={handleMenuItemLinkClick}
              css={{ alignItems: 'flex-end' }}
            >
              <Text type="body1">Search</Text>
            </Link>

            {isCreator && (
              <>
                <Link
                  nounderline
                  href={AppRoutes.EARNINGS}
                  active={asPath === AppRoutes.EARNINGS}
                  onClick={handleMenuItemLinkClick}
                >
                  <Text type="body1">Earnings</Text>
                </Link>

                <Link
                  nounderline
                  href={AppRoutes.SALES}
                  active={asPath === AppRoutes.SALES}
                  onClick={handleMenuItemLinkClick}
                >
                  <Text type="body1">Sales</Text>
                </Link>

                <Link
                  nounderline
                  href={AppRoutes.ANALYTICS}
                  active={asPath === AppRoutes.ANALYTICS}
                  onClick={handleMenuItemLinkClick}
                >
                  <Text type="body1">Stats</Text>
                </Link>

                <Link
                  nounderline
                  href={AppRoutes.SCHEDULED_POSTS}
                  active={asPath === AppRoutes.SCHEDULED_POSTS}
                  onClick={handleMenuItemLinkClick}
                  css={{ whiteSpace: 'nowrap' }}
                >
                  <Text type="body1">Scheduled posts</Text>
                </Link>
              </>
            )}

            {isLoggedIn && (
              <>
                <Link
                  nounderline
                  href={AppRoutes.ACTIVE_SUBS}
                  active={asPath === AppRoutes.ACTIVE_SUBS}
                  onClick={handleMenuItemLinkClick}
                >
                  <Text type="body1">Subscriptions</Text>
                </Link>

                <Link
                  nounderline
                  href={AppRoutes.PURCHASES}
                  active={asPath === AppRoutes.PURCHASES}
                  onClick={handleMenuItemLinkClick}
                >
                  <Text type="body1">Purchases</Text>
                </Link>

                <GradientNavLink
                  css={{ justifyContent: 'end' }}
                  href={AppRoutes.REWARDS}
                  onClick={handleMenuItemLinkClick}
                >
                  <Text type="body1">Rewards</Text>
                </GradientNavLink>
              </>
            )}
          </RightMenuItems>
        </MainContainer>
      </Drawer>
    </>
  )
}

export const BurgerNav = observer(_BurgerNav)
