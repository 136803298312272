import { createContext, Context, ReactNode } from 'react'
import { ComponentProps, CSS } from 'lib/theme'
import { DEFAULT_META_DESC, DEFAULT_META_TITLE } from 'config/seo'
import { Head, SeoProps } from 'shared/Layout/components/Head'
import { GlobalModals } from 'shared/Layout'
import { TopNav } from 'containers/TopNav'
import { Container } from 'common/Container'
import { Footer } from 'common/Footer'
import { Box } from 'primitives/Box'

// @ts-ignore
import twitterCardLogo from 'public/static/images/logo-light.png'

export interface LayoutProps extends ComponentProps<typeof Box>, SeoProps {
  topNavCenterNode?: ReactNode
  disableTopNav?: boolean
  topNavCss?: CSS
}

export const LayoutContext: Context<{ canonical?: string }> = createContext({})

export const Layout: React.FC<LayoutProps> = ({
  metaTitle,
  metaDesc,
  metaImg,
  canonicalUrl,
  og,
  children,
  topNavCenterNode,
  disableTopNav = false,
  css,
  topNavCss
}) => {
  return (
    <LayoutContext.Provider value={{ canonical: canonicalUrl }}>
      <>
        <Head
          metaTitle={metaTitle || DEFAULT_META_TITLE}
          metaDesc={metaDesc || DEFAULT_META_DESC}
          metaImg={metaImg || twitterCardLogo}
          canonicalUrl={canonicalUrl}
          og={og}
        />
        {!disableTopNav && (
          <header>
            <TopNav
              css={{
                position: 'fixed',
                top: '0',
                zIndex: '$layerTwo',
                ...topNavCss
              }}
            >
              {topNavCenterNode}
            </TopNav>
          </header>
        )}
        <Container css={css}>{children}</Container>
        <Footer
          css={{
            display: 'none',
            position: 'fixed',
            bottom: '0',
            '@lg': {
              display: 'block'
            }
          }}
        />
        <GlobalModals />
      </>
    </LayoutContext.Provider>
  )
}
