import { usePrivy } from '@privy-io/react-auth'
import { ComponentProps } from '@stitches/react'
import { Avatar } from 'common/Avatar'
import { Button } from 'common/Button'
import { Icon } from 'common/Icon'
import { Link } from 'common/Link'
import { ConnectModal } from 'common/Web3/ConnectModal'
import { Text } from 'components/primitives/Text'
import {
  DEFAULT_HOME_ROUTE,
  LAYOUT_CENTER_COL_WIDTH,
  TOPNAV_HEIGHT_DESKTOP,
  TOPNAV_HEIGHT_MOBILE
} from 'config'
import { NetworkSelector } from 'containers/NetworkSelector'
import { ProfileDropDown } from 'containers/ProfileDropDown'
import { ProfileSearchDropdown } from 'containers/ProfileSearchDropdown'
import { useIsDesktopSize } from 'hooks/useIsDesktopSize'
import { useStore } from 'lib/store'
import { styled } from 'lib/theme'
import { theme } from 'lib/theme'
import { observer } from 'mobx-react-lite'
import dynamic from 'next/dynamic'
import { Box } from 'primitives/Box'
import { Flex } from 'primitives/Flex'
import { Nav } from 'primitives/Nav'
import { useState } from 'react'
import { AppInfoModal } from 'shared/AppInfoModal'
import { ApplicationBanner } from 'shared/Banner'
import { BurgerNav } from 'shared/BurgerNav'
import { ChatIndicatorProps } from 'shared/ChatIndicator'
import { NotificationsDrawer } from 'shared/NotificationsDrawer'
import { ProfileDrawer } from 'shared/ProfileDrawerMobile'

const ChatIndicator = dynamic<ChatIndicatorProps>(
  () => import('shared/ChatIndicator').then((mod) => mod.ChatIndicator),
  { ssr: false }
)

const NavColsContainer = styled(Flex, {
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  maxWidth: theme.container.maxWidth,
  margin: '0 auto',
  padding: '0px px',
  position: 'relative',
  '@lg': {
    padding: '0 16px'
  }
})

// These cols are _close_ to the cols defined in Layout, but not close enough to share.
const LeftColumn = styled(Flex, {
  height: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@sm': {
    display: 'flex'
  },
  '@lg': {
    display: 'flex',
    width: '234px'
  }
})

const CenterColumn = styled(Flex, {
  margin: '0 auto',
  width: '100%',
  height: '100%',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: 16,
  '@lg': {
    padding: '0 16px',
    width: `${LAYOUT_CENTER_COL_WIDTH}px`,
    minWidth: `${LAYOUT_CENTER_COL_WIDTH}px`
  }
})

const RightColumn = styled(Flex, {
  height: '100%',
  justifyContent: 'end',
  alignItems: 'center',
  '@lg': {
    display: 'flex',
    width: '345px',
    minWidth: '345px'
  }
})

const RightActions = styled(Flex, {
  whiteSpace: 'nowrap',
  width: '100%',
  height: '100%',
  justifyContent: 'end',
  alignItems: 'center',
  '& > button, & > a': {
    width: 40,
    height: 40,
    margin: '0 6px'
  },
  '& > .burger-nav': {
    marginRight: 10
  }
})

const CreatePostButton: React.FC<ComponentProps<typeof Button>> = (props) => (
  <Button
    icon
    theme="secondary"
    css={{
      '& svg > .primary.stroke': {
        stroke: 'white'
      },
      '&:hover': {
        filter: 'brightness(1.05)'
      }
    }}
    {...props}
  >
    <Icon icon="Add" />
  </Button>
)

const cssLogo = {
  svg: {
    path: {
      fill: 'white'
    }
  }
}

export type TopNavProps = ComponentProps<typeof Nav>

const _TopNav: React.FC<TopNavProps> = ({ children, css }) => {
  const store = useStore()
  const currentUser = store.user.account
  const isDesktopSize = useIsDesktopSize(!store.ui.isMobile)
  const [isProfileDropdownOpen, setProfileDropdown] = useState(false)
  const { authenticated, ready } = usePrivy()
  const isAuthenticated = ready && authenticated

  const handleClickCreatePost = async () => {
    store.ui.openCreatePost()
  }

  return (
    <Nav
      css={{
        backgroundColor: theme.colors.gray300,
        width: '100%',
        height: `${TOPNAV_HEIGHT_MOBILE}px`,
        borderBottom: `1px solid ${theme.colors.gray400}`,
        '@lg': {
          borderBottom: 'none',
          height: `${TOPNAV_HEIGHT_DESKTOP}px`,
          ...css?.['@lg']
        },
        ...css
      }}
    >
      <NavColsContainer>
        <LeftColumn>
          <Link
            className="home-link"
            href={DEFAULT_HOME_ROUTE}
            css={{
              marginLeft: 16,
              marginTop: 5,
              display: 'flex',
              '@lg': {
                margin: 0
              }
            }}
            onClick={() => {
              if (!isDesktopSize) {
                store.ui.closeBurgerMenu()
              }
            }}
          >
            <Icon
              icon={isDesktopSize ? 'LogoGradient' : 'AsteriskGradient'}
              css={{
                marginTop: '-4px',
                width: isDesktopSize ? 80 : 30,
                ...cssLogo
              }}
            />
          </Link>

          {/* TODO: Explicity render account type */}
          <Text type="body4">{currentUser?.accountType}</Text>
        </LeftColumn>

        <CenterColumn css={{ display: children || isDesktopSize ? 'flex' : 'none' }}>
          <Box
            css={{
              width: '100%',
              display: children ? 'block' : 'none',
              '@lg': {
                display: 'block'
              }
            }}
          >
            {children ? children : <ProfileSearchDropdown />}
          </Box>
        </CenterColumn>

        <RightColumn css={{ width: children || isDesktopSize ? 'auto' : '100%' }}>
          <RightActions>
            {!isDesktopSize ? (
              // -------------------
              //    MOBILE NAV
              // -------------------
              <>
                {/* "Zen mode" for mobile with children rendered into topnav i.e post detail page. */}
                {!children && (
                  <>
                    <NetworkSelector />

                    {!isAuthenticated ? <ConnectModal /> : null}

                    {currentUser && (
                      <>
                        <ChatIndicator />
                        <NotificationsDrawer />
                        <Button onClick={() => setProfileDropdown(true)}>
                          <Avatar
                            src={currentUser?.avatar?.url}
                            username={currentUser.username}
                            size="small"
                          />
                        </Button>
                      </>
                    )}
                    <BurgerNav />
                  </>
                )}
              </>
            ) : (
              // -------------------
              //    DESKTOP NAV
              // -------------------
              <>
                <AppInfoModal />

                <NetworkSelector />

                <ConnectModal />
                {/* <ConnectWalletAuthModal /> */}
                {currentUser && (
                  <>
                    <ChatIndicator />
                    <NotificationsDrawer />
                    {store.user.isCreator && <CreatePostButton onClick={handleClickCreatePost} />}
                    <ProfileDropDown user={currentUser} />
                  </>
                )}
              </>
            )}
          </RightActions>
        </RightColumn>
      </NavColsContainer>

      {/* Banner notifications */}
      <ApplicationBanner />

      {/* Mobile Profile DropDown */}
      <ProfileDrawer isOpen={isProfileDropdownOpen} onIgnore={() => setProfileDropdown(false)} />
    </Nav>
  )
}

export const TopNav = observer(_TopNav)
