import { Button } from 'common/Button'
import { styled, ComponentProps } from 'lib/theme'
import { Flex } from 'primitives/Flex'
import { Text } from 'primitives/Text'

const StyledButton = styled(Button, {
  position: 'relative',
})

const Count = styled(Flex, {
  position: 'absolute',
  borderRadius: '50%',
  backgroundColor: '$red100',
  width: 23,
  height: 23,
  padding: 9,
  top: -6,
  right: -6,
})

const CountText = styled(Text, {
  color: '$gray900 !important',
})

interface IconWithCountProps extends ComponentProps<typeof StyledButton> {
  count: number
  top?: number
  right?: number
}

export const IconButtonWithCount: React.FC<IconWithCountProps> = ({
  count,
  children,
  top,
  right,
  onClick,
  css,
  ...props
}) => {
  const calcFontSize = () => {
    switch (true) {
      case count > 10000:
        return 7
      case count > 1000:
        return 8
      case count > 100:
        return 10
      default:
        return 12
    }
  }
  return (
    <StyledButton icon theme='secondary' css={css} onClick={onClick} {...props}>
      <>
        {children}
        {count > 0 && (
          <Count center css={{ top, right }}>
            <CountText type='caption1' css={{ fontSize: calcFontSize() }}>
              {count}
            </CountText>
          </Count>
        )}
      </>
    </StyledButton>
  )
}
