import { Transition, animated } from '@react-spring/web'
import { ComponentProps } from '@stitches/react'
import { useHideBodyScrollbar } from 'hooks/useHideBodyScrollbar'
import { useOnKeyDown } from 'hooks/useOnKeyDown'
import { setColorOpacity, styled, theme } from 'lib/theme'
import { Children, MouseEvent, cloneElement, isValidElement, useEffect, useRef } from 'react'
import { ModalPortal } from './ModalPortal'
import { DEFAULT_MODAL_ANIMATIONS, ModalAnimation, isTransitionProps } from './animations'

const ModalContainer = styled(animated.div, {
  position: 'fixed',
  overflow: 'auto',
  right: '0px',
  left: '0px',
  opacity: 0,
  height: '100%',
  minWidth: '100%',
  maxWidth: '100%',
  width: '100%',
  zIndex: theme.zIndices.modal,
  backgroundColor: 'transparent',
  '@lg': {
    backgroundColor: setColorOpacity(theme.colors.gray100, 0.5)
  },
  variants: {
    backdrop: {
      clear: {
        backdropFilter: 'unset'
      },
      blurred: {
        // backdropFilter: 'blur(5px)'
      }
    }
  },
  defaultVariants: {
    backdrop: 'blurred'
  }
})

export interface ModalProps extends ComponentProps<typeof ModalContainer> {
  animation?: ModalAnimation
  animationDurationMs?: number
  isOpen?: boolean
  // Control whether document body scrollbar is hidden when the modal opens (useful to disable when layering modals).
  lockScrollbar?: boolean
  onBackgroundClick?: (e?: MouseEvent<HTMLDivElement>) => void
}

export const Modal: React.FC<ModalProps> = ({
  children,
  css,
  animation = 'fadeIn',
  animationDurationMs = 250,
  isOpen,
  lockScrollbar = true,
  onBackgroundClick,
  style
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const selectedAnimation = isTransitionProps(animation)
    ? animation
    : DEFAULT_MODAL_ANIMATIONS[animation]

  useHideBodyScrollbar(lockScrollbar && isOpen)

  useOnKeyDown({
    key: 'Escape',
    action: () => {
      if (isOpen) {
        onBackgroundClick?.()
      }
    }
  })

  useEffect(() => {
    const body = document.body
    if (body) {
      body.style.overflowY = isOpen ? 'hidden' : 'auto'
    }

    return () => {
      body.style.overflowY = 'auto'
    }
  }, [isOpen])

  // console.log('css2', css)

  return (
    <ModalPortal>
      <Transition items={isOpen} {...selectedAnimation} config={{ duration: animationDurationMs }}>
        {(styles, show) =>
          show && (
            <ModalContainer
              ref={containerRef}
              onClick={(e) => {
                onBackgroundClick?.(e)
              }}
              css={css}
              style={{ ...style, ...styles }}
            >
              {Children.map(children, (child) => {
                return isValidElement(child)
                  ? cloneElement(child as React.ReactElement, {
                      ...child.props,
                      onClick: (ev: MouseEvent) => {
                        ev.stopPropagation()
                        child.props.onClick?.(ev)
                      }
                    })
                  : child
              })}
            </ModalContainer>
          )
        }
      </Transition>
    </ModalPortal>
  )
}
